import React from "react";

export const Button = ({ children, onClick, disabled, className }) => (
    <button
        onClick={onClick}
        disabled={disabled}
        className={`px-4 py-2 bg-black text-white rounded-full hover:bg-burgundydisabled:bg-gray-400 ${className}`}
    >
        {children}
    </button>
);

export const Input = ({
    type,
    id,
    value,
    onChange,
    placeholder,
    className,
}) => (
    <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${className}`}
    />
);

export const Label = ({ htmlFor, children, className }) => (
    <label
        htmlFor={htmlFor}
        className={`block text-sm font-medium text-gray-700 ${className}`}
    >
        {children}
    </label>
);

export const toast = {
    error: ({ title, description }) => {
        console.error(`${title}: ${description}`);
        // Implement your toast logic here
    },
    success: ({ title, description }) => {
        console.log(`${title}: ${description}`);
        // Implement your toast logic here
    },
};
