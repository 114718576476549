import React from "react";
import { FormattedMessage } from "react-intl";
import aboutImage1 from "../assets/images/heroimg.jpg";
import { Link } from "react-router-dom";

function About() {
    return (
        <div className="flex-grow">
            <main className="flex flex-col">
                {/* Top section */}
                <section className="bg-beige py-12 sm:py-16 md:py-20">
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex flex-col md:flex-row md:items-start">
                            <div className="w-full md:w-1/2 lg:w-2/5 mb-8 md:mb-0 md:sticky md:top-20 lg:pl-12">
                                <div className="max-w-[200px] sm:max-w-[250px] md:max-w-[350px] overflow-hidden rounded-lg relative mx-auto md:mx-0">
                                    <img
                                        src={aboutImage1}
                                        alt="About"
                                        className="w-full h-auto object-cover object-top"
                                    />
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 lg:w-3/5 text-center md:text-left md:pl-8 lg:pl-12">
                                <h2 className="mb-4">
                                    <FormattedMessage id="about.title" />
                                </h2>
                                <p className="mb-6 italic">
                                    <FormattedMessage id="about.subtitle" />
                                </p>

                                <div className="space-y-4">
                                    <p>
                                        <FormattedMessage id="about.p1" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="about.p1.a" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="about.p1.b" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="about.p1.c" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Middle section */}
                <section className="bg-burgundy py-12 sm:py-16 md:py-20 text-beige">
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex flex-col md:flex-row items-center">
                            <div className="w-full md:w-1/3 mb-6 md:mb-0 flex justify-center">
                                <h4 className="text-2xl lg:text-3xl text-center">
                                    <FormattedMessage id="about.t2" />
                                </h4>
                            </div>
                            <div className="w-full md:w-2/3 md:pl-8">
                                <div className="space-y-4">
                                    <p>
                                        <FormattedMessage id="about.p2" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="about.p2.a" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="about.p2.b" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Bottom section */}
                <section className="bg-beige py-12 sm:py-16 md:py-20">
                    <div className="container mx-auto px-12">
                        <div className="flex flex-col items-center">
                            <h2 className="mb-6">
                                <FormattedMessage id="about.p3.d" />
                            </h2>
                            <Link to="/contactme" className="btn-primary">
                                <FormattedMessage id="legal.contactButton" />
                            </Link>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default About;
