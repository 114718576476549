import React from "react";
import { FormattedMessage } from "react-intl";

function TermsConditions() {
    return (
        <div className="flex-grow">
            <main className="flex items-stretch">
                <section
                    id="about"
                    className="w-full bg-beige font-pt py-20 flex-grow"
                >
                    <div className="container mx-auto px-4 flex flex-col md:flex-row items-start justify-center">
                        <div className="w-full md:w-2/3 text-center md:text-left md:pl-8 font-marcellus">
                            <h2 className="font-bold leading-tight mb-6">
                                <FormattedMessage id="terms.title" />
                            </h2>
                            <h3 className="mb-4">
                                <FormattedMessage id="terms.h1" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t1.a" />
                                <a href="https://www.lexconnect.ch">
                                    <FormattedMessage id="terms.t1.b" />
                                </a>
                                <FormattedMessage id="terms.t1.c" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t1.d" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t1.e" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t1.f" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t1.g" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t1.h" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t1.i" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t1.j" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t1.k" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="terms.h2" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t2.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t2.b" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t2.c" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="terms.h3" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t3.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t3.b" />
                            </p>
                            <ul className="list-disc list-inside">
                                <li>
                                    <FormattedMessage id="terms.t3.p1" />
                                </li>
                                <li>
                                    <FormattedMessage id="terms.t3.p2" />
                                </li>
                            </ul>
                            <p className="mb-4 mt-4">
                                <FormattedMessage id="terms.t3.c" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t3.d" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t3.e" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="terms.h4" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t4.a" />
                            </p>
                            <p className="mb-4 mt-4">
                                <FormattedMessage id="terms.t4.b" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="terms.h5" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t5.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t5.b" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t5.c" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t5.d" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="terms.h6" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t6.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t6.b" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t6.c" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="terms.h7" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t7.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t7.b" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t7.c" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t7.d" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="terms.h8" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t8.a" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="terms.h9" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t9.a" />
                            </p>
                            <p className="mt-4 mb-4">
                                <FormattedMessage id="terms.t9.b" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="terms.h10" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t10.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t10.b" />
                            </p>
                            <p className="mt-4 mb-4">
                                <FormattedMessage id="terms.t10.c" />
                            </p>
                            <p className="mt-4 mb-4">
                                <FormattedMessage id="terms.t10.d" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="terms.h11" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t11.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t11.b" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="terms.h12" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t12.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t12.b" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t12.c" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="terms.h13" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="terms.t13.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="terms.lastUpdated" />
                            </p>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default TermsConditions;
