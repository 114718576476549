import React from "react";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";

// Footer component: Renders the website footer with contact information, legal links, and social media icons
function Footer() {
    return (
        <footer className="bg-burgundy text-beige py-6 font-merriweather">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-stretch">
                {/* Location information */}
                <div className="flex-1 flex justify-center mb-4 md:mb-0">
                    <div className="flex flex-col items-center pt-4">
                        <p className="text-sm">
                            <FormattedMessage id="footer.swiss" />
                        </p>
                        <p className="text-sm">
                            <FormattedMessage id="footer.location" />
                        </p>
                    </div>
                </div>
                {/* Vertical divider, visible on medium screens and up */}
                <div className="hidden md:block w-px h-auto bg-beige mx-4"></div>
                {/* Contact, legal notices, and links */}
                <div className="flex-1 flex flex-col items-center text-center text-sm pt-4">
                    <a href="/LegalNotices" className="hover:underline">
                        <FormattedMessage id="footer.legalnotices" />
                    </a>
                    <a href="/Privacy" className="hover:underline">
                        <FormattedMessage id="footer.privacy" />
                    </a>
                    <a href="/TermsConditions" className="hover:underline">
                        <FormattedMessage id="footer.terms" />
                    </a>
                </div>
                {/* Vertical divider, visible on medium screens and up */}
                <div className="hidden md:block w-px h-auto bg-beige mx-4"></div>
                {/* Social media icons */}
                <div className="flex-1 flex justify-center">
                    <div className="flex items-center pt-4">
                        <a
                            href="https://instagram.com"
                            className="mr-4 hover:text-[#f9f9f1]/80 text-xl sm:text-xl md:text-2xl"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a
                            href="https://linkedin.com"
                            className="hover:text-[#f9f9f1]/80 text-xl sm:text-xl md:text-2xl"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                    </div>
                </div>
            </div>
            {/* Copyright information */}
            <div className="mt-4 text-center">
                <p className="text-sm">
                    &copy; {new Date().getFullYear()}{" "}
                    <FormattedMessage id="footer.copyright" />
                </p>
            </div>
        </footer>
    );
}

export default Footer;
