import React from "react";
import { FormattedMessage } from "react-intl";
import heroImage from "../assets/images/hero2.jpg";

// Hero component: Displays the main hero section of the website
function Hero() {
    return (
        // Hero section with beige background
        <section id="hero" className="w-full bg-beige py-10">
            {/* Container for hero content */}
            <div className="container mx-auto px-4 flex flex-col md:flex-row-reverse items-center justify-center">
                {/* Image container */}
                <div className="w-full md:w-1/2 mb-8  md:pl-8">
                    {/* Circular image container with responsive sizing */}
                    <div className="max-w-[300px] sm:max-w-[400px] md:max-w-[500px] aspect-square overflow-hidden rounded-full relative mx-auto mt-10 md:ml-auto">
                        <img
                            src={heroImage}
                            alt="Hero"
                            className="w-full h-full object-cover object-center"
                            style={{ objectPosition: "center 30%" }}
                        />
                    </div>
                </div>
                {/* Text content container */}
                <div className="w-full md:w-1/2 text-center md:text-left px-6 sm:px-4 md:px-0">
                    <div className="text-black">
                        {/* Hero title */}
                        <h1 className="my-8 leading-tight">
                            <FormattedMessage id="hero.title" />
                        </h1>
                        {/* Hero description paragraphs */}
                        <p className="mb-4">
                            <FormattedMessage id="hero.description" />
                        </p>
                        <p className="mb-8">
                            <FormattedMessage id="hero.description2" />
                        </p>
                        {/* Call-to-action button */}
                        <div className="flex justify-center md:justify-start">
                            <a href="/about">
                                <button className="btn-primary">
                                    <FormattedMessage id="hero.button" />
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
