import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { API_URL } from "../config";

// Component for handling file downloads using a token-based system
function Download() {
    // Extract the token from the URL parameters
    const { token } = useParams();
    // State to track the download status (idle, loading, success, error)
    const [status, setStatus] = useState("idle");
    // State to store the filename
    const [filename, setFilename] = useState("");

    // Function to initiate and handle the file download process
    const handleDownload = async () => {
        setStatus("loading");
        try {
            // Fetch the file from the API using the provided token
            const response = await fetch(`${API_URL}/download/${token}`);
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();
                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Extract the filename from the Content-Disposition header
                const contentDisposition = response.headers.get(
                    "Content-Disposition"
                );
                let filename = "download.pdf"; // Default filename
                if (contentDisposition) {
                    const filenameMatch =
                        contentDisposition.match(/filename="?(.+)"?/i);
                    if (filenameMatch && filenameMatch.length === 2)
                        filename = filenameMatch[1];
                }
                setFilename(filename); // Set the filename in the component state

                // Create a temporary anchor element to trigger the download
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();

                // Clean up: remove the anchor and revoke the blob URL
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);

                setStatus("success");
            } else {
                throw new Error("Download failed");
            }
        } catch (error) {
            console.error("Error downloading file:", error);
            setStatus("error");
        }
    };

    // Render the download component with different states
    return (
        <div className="bg-beige min-h-screen flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
                <h1 className="text-2xl font-bold mb-4">
                    <FormattedMessage
                        id="download.titleWithFilename"
                        values={{
                            filename: filename || (
                                <FormattedMessage id="download.defaultFilename" />
                            ),
                        }}
                    />
                </h1>
                {/* Render different content based on the download status */}
                {status === "idle" && (
                    <button onClick={handleDownload} className="btn-primary">
                        <FormattedMessage id="download.button" />
                    </button>
                )}
                {status === "loading" && (
                    <p>
                        <FormattedMessage id="download.loading" />
                    </p>
                )}
                {status === "success" && (
                    <p>
                        <FormattedMessage id="download.success" />
                    </p>
                )}
                {status === "error" && (
                    <p>
                        <FormattedMessage id="download.error" />
                    </p>
                )}
            </div>
        </div>
    );
}

export default Download;
