import React from "react";
import { FormattedMessage } from "react-intl";

function LegalNotices() {
    return (
        <div className="flex-grow">
            <main className="flex items-stretch">
                <section id="about" className="w-full bg-beige py-20 flex-grow">
                    <div className="container mx-auto px-4 flex flex-col md:flex-row items-start justify-center">
                        <div className="w-full md:w-2/3 text-center md:text-left md:pl-8 font-marcellus">
                            <h1 className="mb-6">
                                <FormattedMessage id="legalnotice.title" />
                            </h1>
                            <h3 className="mt-6 mb-4">
                                <FormattedMessage id="legalnotice.h1" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t1.a" />
                            </p>
                            <h3 className="text-black leading-tight mt-4 mb-4">
                                <FormattedMessage id="legalnotice.h2" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t2.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t2.b" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t2.c" />
                            </p>
                            <h3 className="mt-4 mb-4">
                                <FormattedMessage id="legalnotice.h3" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t3.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t3.b" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t3.c" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t3.d" />
                            </p>
                            <h3 className="mt-4 mb-4">
                                <FormattedMessage id="legalnotice.h4" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t4.a" />
                            </p>
                            <h3 className="mt-4 mb-4">
                                <FormattedMessage id="legalnotice.h5" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t5.a" />
                            </p>
                            <h3 className="mt-4 mb-4">
                                <FormattedMessage id="legalnotice.h6" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t6.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t6.b" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t6.c" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t6.d" />
                            </p>
                            <h3 className="mt-4 mb-4">
                                <FormattedMessage id="legalnotice.h7" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t7.a" />
                            </p>
                            <h3 className="mt-4 mb-4">
                                <FormattedMessage id="legalnotice.h8" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t8.a" />
                            </p>
                            <h3 className="mt-4 mb-4">
                                <FormattedMessage id="legalnotice.h9" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="legalnotice.t9.a" />
                            </p>
                            <p className="mt-4 mb-4">
                                <FormattedMessage id="legalnotice.lastUpdated" />
                            </p>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default LegalNotices;
