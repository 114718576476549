import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

// Main component for the Legal page
function Legal() {
    // FAQ items with questions and answers
    const faqItems = [
        {
            question: <FormattedMessage id="legal.faq.question1" />,
            answer: <FormattedMessage id="legal.faq.answer1" />,
        },
        {
            question: <FormattedMessage id="legal.faq.question2" />,
            answer: <FormattedMessage id="legal.faq.answer2" />,
        },
        {
            question: <FormattedMessage id="legal.faq.question3" />,
            answer: <FormattedMessage id="legal.faq.answer3" />,
        },
        {
            question: <FormattedMessage id="legal.faq.question4" />,
            answer: <FormattedMessage id="legal.faq.answer4" />,
        },
        // Add more FAQ items as needed
    ];

    // State to manage which dropdown is currently open
    const [openDropdown, setOpenDropdown] = useState(null);
    const intl = useIntl();

    // Function to toggle the open/closed state of dropdowns
    const toggleDropdown = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    const renderDescription = (block) => {
        const description = intl.formatMessage({
            id: `legal.${block}.description`,
        });

        if (block === "block3") {
            const parts = description.split("{link}");
            return (
                <>
                    {parts[0]}
                    <Link to="/shop" className="underline">
                        {intl.formatMessage({ id: "legal.block3.linkText" })}
                    </Link>
                    {parts[1]}
                </>
            );
        }

        return description;
    };

    return (
        <div className="flex-grow">
            {/* Hero section with title, description, and CTA */}
            <div className="bg-beige py-12 sm:py-16 md:py-20 lg:py-24 px-4 sm:px-6 lg:px-8">
                <div className="container mx-auto px-4 max-w-6xl">
                    <div className="flex flex-col md:flex-row md:space-x-6 lg:space-x-8">
                        {/* Left side with main content */}
                        <div className="md:w-1/2 mb-8 md:mb-0 px-4">
                            <h1 className="text-black mb-6">
                                <FormattedMessage id="legal.title" />
                            </h1>
                            <p className="text-black mb-8">
                                <FormattedMessage id="legal.description" />
                            </p>
                            <Link to="/contactme" className="btn-primary">
                                <FormattedMessage id="legal.contactButton" />
                            </Link>
                        </div>

                        {/* Right side with dropdown blocks */}
                        <div className="md:w-1/2">
                            <div className="space-y-0">
                                {/* Mapping through blocks to create dropdowns */}
                                {["block1", "block2", "block3", "block4"].map(
                                    (block, index) => (
                                        <Dropdown
                                            key={index}
                                            title={intl.formatMessage({
                                                id: `legal.${block}.title`,
                                            })}
                                            description={renderDescription(
                                                block
                                            )}
                                            isOpen={openDropdown === index}
                                            toggleDropdown={() =>
                                                toggleDropdown(index)
                                            }
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* FAQ section */}
            <div className="bg-white py-16">
                <div className="container mx-auto px-4">
                    <h2 className="pb-10 text-center">
                        <FormattedMessage id="legal.faq" />
                    </h2>
                    <FAQ items={faqItems} />
                </div>
            </div>

            {/* Contact CTA section */}
            <div className="bg-beige py-20">
                <div className="container mx-auto px-4 max-w-6xl">
                    <div className="flex flex-col items-center text-center px-4">
                        <h3 className="mb-8">
                            <FormattedMessage id="legal.contact" />
                        </h3>
                        <Link to="/contactme" className="btn-primary">
                            <FormattedMessage id="legal.contactButton" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Reusable Dropdown component
function Dropdown({ title, description, isOpen, toggleDropdown }) {
    return (
        <div className="border-b last:border-b-0">
            {/* Dropdown button */}
            <button
                onClick={toggleDropdown}
                className="flex justify-between items-center w-full p-3 sm:p-4 text-left text-black"
            >
                <span className="text-base md:text-lg lg:text-lg font-merriweather ml-2 sm:ml-4 md:ml-4 lg:ml-8">
                    {title}
                </span>
                <span className="text-lg md:text-xl lg:text-2xl flex-shrink-0 mr-2 sm:mr-4 md:mr-4 lg:mr-8">
                    {isOpen ? "-" : "+"}
                </span>
            </button>
            {/* Dropdown content */}
            {isOpen && (
                <div className="p-3 sm:p-4 mx-2 sm:mx-4 md:mx-4 lg:mx-8">
                    <p className="text-black">{description}</p>
                </div>
            )}
        </div>
    );
}

// FAQ component
function FAQ({ items }) {
    const [openItem, setOpenItem] = useState(null);

    // Function to toggle open/closed state of FAQ items
    const toggleItem = (index) => {
        setOpenItem(openItem === index ? null : index);
    };

    return (
        <div className="space-y-0 max-w-4xl mx-auto px-8 sm:px-10 md:px-12 lg:px-16">
            {/* Mapping through FAQ items */}
            {items.map((item, index) => (
                <div
                    key={index}
                    className="border-b-2 border-gray-200 last:border-b-0"
                >
                    {/* FAQ question button */}
                    <button
                        className="flex justify-between items-center w-full py-4 text-left text-black"
                        onClick={() => toggleItem(index)}
                    >
                        <span className="text-base md:text-lg lg:text-lg font-merriweather pr-8 flex-grow">
                            {item.question}
                        </span>
                        <span className="text-lg md:text-xl lg:text-2xl flex-shrink-0">
                            {openItem === index ? "-" : "+"}
                        </span>
                    </button>
                    {/* FAQ answer (shown when item is open) */}
                    {openItem === index && (
                        <div className="pb-4">
                            <p className="text-black">{item.answer}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default Legal;
