const enMessages = {
    // Header
    "header.title": "LEX Connect.",

    // Navigation
    "nav.home": "Home",
    "nav.about": "About",
    "nav.services": "Services",
    "nav.translation": "Language",
    "nav.legal": "Legal",
    "nav.shop": "Shop",
    "nav.resources": "Bonus",
    "nav.contactme": "Contact",

    // Hero section
    "hero.title": "Your Partner in Law & Language",
    "hero.button": "About",
    "hero.description":
        "I'm Alexandra, an independent legal consultant and freelance nomad with international experience. My unique background allows me to combine two key areas of expertise.",
    "hero.description2":
        "Whether you're looking to strengthen your legal compliance or expand into French-speaking markets, you’re not alone. I'm here to simplify your journey.",

    // Contact form
    "contact.title": "Get in Touch",
    "contact.error":
        "Please fill in all required fields and select at least one service.",
    "contact.firstName": "First Name",
    "contact.lastName": "Last Name",
    "contact.email": "Email",
    "contact.errorInvalidEmail": "Please enter a valid email address.",
    "contact.message": "Your message",
    "contact.service": "Services you are interested in",
    "contact.legal": "Legal",
    "contact.translation": "Translation",
    "contact.other": "Other",
    "contact.acceptTerms": "By submitting this form, I accept the ",
    "contact.acceptTerms.link": "Privacy Policy.",
    "contact.submit": "Submit",
    "contact.errorSending": "Failed to send message. Please try again later.",
    "contact.errorAllFields": "Please fill in all required fields.",
    "contact.errorTerms": "You must accept the Privacy policy to proceed.",
    "contact.confirmationTitle": "Message Sent!",
    "contact.confirmationMessage":
        "Thank you for your message. We'll get back to you soon!",
    "contact.confirmationClose": "Close",

    // Footer
    "footer.swiss": "Switzerland",
    "footer.location": "Virtually anywhere",
    "footer.copyright": "LEX Connect. All rights reserved.",
    "footer.privacy": "Privacy Policy",
    "footer.terms": "Terms and Conditions",
    "footer.legalnotices": "Legal Notices",

    // Success
    "success.title": "Your payment was successful!",
    "success.message":
        "Thanks for your purchase! A email has been sent with the purchased Template. Please check your inbox and spam folder.",
    "success.redirect": "Return to homepage",

    // Download
    "download.title": "Your file is now available for download",
    "download.titleWithFilename": "Download {filename}",
    "download.button": "Download",
    "download.loading": "Preparing your download...",
    "download.success":
        "Your download has started, please check your downloads folder.",
    "download.error":
        "Sorry, there was an error processing your download. The link may have expired or be invalid. Please contact me if the issue persists.",

    // Cookie consent
    "cookie.consent":
        "This website uses cookies to enhance your experience and provide essential functionality. ",

    // About page
    "about.title": "Hi there, I'm Alexandra!",
    "about.subtitle": "I’m a legal consultant and freelance nomad.",
    "about.t1": "A Blend of Legal Expertise and Multicultural Insight",
    "about.p1":
        "My journey began with a Master's degree in Law from the University of Louvain in Belgium, followed by a Master's in Management from the Louvain School of Management.",
    "about.p1.a":
        "After six rewarding years as a legal consultant and corporate officer in various companies, I felt the urge to broaden my horizons. I believe that stepping out of one’s comfort zone enriches both vision and expertise.",
    "about.p1.b":
        "In 2020, during the pandemic, I unexpectedly found myself stuck outside Europe. This situation led me to help businesses connect with French-speaking markets through copywriting and translation. I soon discovered that law and communication share a key principle: every word counts!",
    "about.p1.c":
        "Today, I draw on this diverse background to provide legal expertise to my Swiss clients and linguistic services to an international clientele.",

    "about.t2": "A Nomadic and Entrepreneurial Spirit",
    "about.p2":
        "My office? The entire world. As a digital nomad, I’ve traveled extensively and built connections with clients across the globe.",
    "about.p2.a":
        "This experience has taught me an essential lesson: we all face similar challenges, whether personal or professional.",
    "about.p2.b":
        "When you collaborate with me, you gain more than just a service provider. You gain a dedicated partner who understands your challenges because I experience them myself.",

    "about.t3": "My Approach",
    "about.p3":
        "When you collaborate with me, you gain more than just a service provider. You gain:",
    "about.p3.a":
        "A dedicated partner who understands your challenges because I experience them myself.",

    "about.p3.b": "An approach rooted in simplicity and transparency.",
    "about.p3.c":
        "Tailored solutions that allow you to focus on what you do best.",
    "about.p3.d": "Ready to work together?",

    // Work together section
    "work.title": "We should work together if",
    "work.section1": "You want to focus on what matters most",
    "work.description1":
        "Let me handle the legal and/or language details, so you can focus your time and energy on growing and managing your business.",
    "work.section2": "You need tailored solutions",
    "work.description2":
        "Your business is unique; benefit from services tailored to your specific needs.",
    "work.section3": "You’re looking for a trusted partner",
    "work.description3":
        "You deserve someone who listens to you, supports you, and is committed to your success.",

    // Translation page // Translation page // Translation page // Translation page // Translation page
    "translation.title": "Language Services",
    "translation.description":
        "A range of translation services tailored to your needs, including marketing, website, and legal translations, carefully adapted for French-speaking audiences while preserving the original intent and tone.",
    "translation.packages": "Packages",
    "translation.services": "Services",
    "translation.packagesText": "Looking for an All-in-One Solution?",
    "translation.packagesText2":
        "Choose the package that best fits your needs, with or without additional add-ons, and enjoy a streamlined process from start to finish.",
    "translation.contactButton": "Contact me",

    // Translation block
    "translation.block1.title": "Translation",
    "translation.block1.description":
        "A range of translation services tailored to your needs, including marketing, website, and legal translations, carefully adapted for French-speaking audiences while preserving the original intent and tone.",
    "translation.block2.title": "Copywriting",
    "translation.block2.description":
        "Engaging and persuasive copy for your website, marketing materials, and social media, crafted to captivate your audience and reflect your brand’s unique voice.",
    "translation.block3.title": "Editing",
    "translation.block3.description":
        "Enhancing your text for clarity and impact, with a focus on readability, consistency, and coherence to ensure polished and professional content.",
    "translation.block4.title": "SEO",
    "translation.block4.description":
        "Optimizing your content with strategic keyword integration to boost search engine visibility and attract targeted traffic.",

    // Translation Basic Package
    "translation.basic": "Basic",
    "translation.needs": "For you if:",
    "translation.basic.needs.description":
        "For businesses that need a simple and effective translation solution.",
    "translation.features": "Package Includes",
    "translation.basic.features.description":
        "For businesses that need a simple and effective translation solution.",
    "translation.basic.price": "999 CHF",

    // Translation Superior Package
    "translation.superior": "Superior",

    "translation.superior.needs.description":
        "For businesses that need a simple and effective translation solution.",

    "translation.superior.features.description":
        "For businesses that need a simple and effective translation solution.",
    "translation.superior.price": "1999 CHF",

    // Translation Superior Plus Package
    "translation.superior.plus": "Superior+",

    "translation.superior.plus.needs.description":
        "For businesses that need a simple and effective translation solution.",

    "translation.superior.plus.features.description":
        "For businesses that need a simple and effective translation solution.",
    "translation.superior.plus.price": "2999 CHF",

    // Translation faq
    "translation.faq": "FAQ",
    "translation.faq.question1":
        "Why should I choose professional translation services?",
    "translation.faq.answer1":
        "Automated tools often lack the depth and nuance needed to capture your message accurately. AI struggles to convey your unique brand voice as effectively as a professional translator. Moreover, sticking to English-only content may cause you to miss out on important opportunities in the French market. ",
    "translation.faq.question2": "What languages do you translate?",
    "translation.faq.answer2":
        "I primarily specialize in translating from English to French, particularly for the markets in Switzerland, Belgium, France, and Luxembourg. Additionally, I can handle translations from German to French when needed.",
    "translation.faq.question3":
        "What are your rates for translation and copywriting services?",
    "translation.faq.answer3":
        "Rates vary based on the complexity and scope of the project. Pricing is influenced by factors such as word count, technicality, and turnaround time. For a detailed and tailored quote, please contact me directly.",
    "translation.faq.question4": "Can you handle urgent projects?",
    "translation.faq.answer4":
        "Yes, I can accommodate urgent projects. Please note that an additional fee may apply for expedited services. While I strive to meet tight deadlines, availability depends on the complexity and volume of the work. Providing advance notice helps ensure the best results. Contact me with your project details, and I will do my best to assist you promptly.",

    // Translation contact
    "translation.contact":
        "Feel free to reach out for any questions or additional information.",
    "translation.contact.2":
        "I’m here to help you strengthen your French presence, engage more effectively with customers, ensure brand consistency, and stand out from the competition!",
    "translation.contact.button": "Contact me",

    // Legal page // Legal page // Legal page // Legal page // Legal page // Legal page // Legal page // Legal page // Legal page
    "legal.title": "Legal Services",
    "legal.description":
        "Tailored solutions for Entrepreneurs & Small Businesses in Switzerland, simplifying legal complexities so you can focus on what matters most.",
    "legal.contactButton": "Contact me",

    // Legal block
    "legal.block1.title": "Document Review & Update",
    "legal.block1.description":
        "Ensure clarity, accuracy, and compliance with current standards. Benefit from comprehensive reviews and updates tailored to your needs.",
    "legal.block2.title": "Custom Document Crafting",
    "legal.block2.description":
        "Get contracts and other documents drafted to meet your specific needs, safeguarding your interests and ensuring clear terms.",
    "legal.block3.title": "Customizable Templates",
    "legal.block3.description":
        "Access a variety of {link} designed for different needs and business profiles. Easily adapt them to fit your situation, saving time and effort.",
    "legal.block3.linkText": "legal templates in the shop",
    "legal.block4.title": "On-Demand Services",
    "legal.block4.description":
        "If your needs don’t fit the above categories, just reach out. Custom solutions are available to address your unique requirements.",

    // Legal faq
    "legal.faq": "FAQ",
    "legal.faq.question1": "Do you work with small businesses and startups?",
    "legal.faq.answer1":
        "Yes, I love working with small businesses. As an entrepreneur myself, I understand your unique needs and can offer personalized support that aligns with your vision.",
    "legal.faq.question2":
        "Should I consider hiring a lawyer instead of using your services?",
    "legal.faq.answer2":
        "As an independent legal consultant not registered with the bar, I provide legal services based on my expertise. However, I am not authorized to represent clients in court or offer services reserved for licensed lawyers. My services do not replace the legal advice or support of a lawyer when necessary or recommended.",
    "legal.faq.question3": "What are your fees for legal services?",
    "legal.faq.answer3":
        "Fees depend on the service and its complexity. I offer both flat-rate packages for specific services and hourly rates for others. Contact me to discuss your needs and get a personalized quote.",
    "legal.faq.question4":
        "Why are there free or cheaper legal resources available online?",
    "legal.faq.answer4":
        "While free or low-cost options, such as generic templates or AI tools, may seem appealing, they often lack the customization and precision necessary for your unique needs. In contrast, my services offer tailored solutions that guarantee accuracy and relevance for your specific situation.",

    // Legal contact
    "legal.contact":
        "Protect yourself, boost your credibility, enjoy peace of mind",
    "legal.contact.2":
        "As an independent legal consultant not registered with the bar, I provide legal services based on my expertise. However, I am not authorized to represent clients in court or offer services reserved for licensed attorneys. My services do not replace the advice and support of a qualified lawyer when needed or recommended.",
    "legal.contact.button": "Contact me",

    // shop page
    "shop.title": "Templates Corner",
    "shop.description":
        "Check out our downloadable and customizable legal templates, designed specifically for entrepreneurs and freelancers in Switzerland. These templates will save you time and help you easily create your essential documents. Currently, they are available in French only. If you need them in English, please feel free to contact me; I may have something available for you.",
    "shop.description.a": "",
    "shop.purchase.button": "Coming soon",
    "shop.disclaimer":
        "As an independent legal consultant not registered with the bar, I provide legal services based on my expertise. However, I am not authorized to represent clients in court or offer services reserved for licensed lawyers. My services do not replace the legal advice or support of a lawyer when necessary or recommended.",

    "shop.title.1": "Service Agreement",
    "shop.description.1": "Standard template including key elements",
    "shop.title.2": "Terms and Conditions of Sale",
    "shop.description.2":
        "Designed for freelancers and creative profiles selling digital products online",

    // Resources page
    "resources.title": "Free Resources",
    "resources.description":
        "This page offers a free guide and tools to help entrepreneurs and freelancers in Switzerland navigate the legal landscape. Currently, the resource is available in French only. If there is enough interest, we may provide additional resources in English in the future.",
    "resources.button": "Download",
    "resources.title.1":
        "Freebie for Swiss Freelancers and Entrepreneurs with Websites",
    "resources.description.1":
        "Explore essential documents and practical tips to ensure legal compliance, protect your business, and boost your online credibility.",
    "resources.title.2": "Swiss Business Law Handbook",
    "resources.description.2":
        "A handy guide to the legal framework for businesses in Switzerland.",
    "resources.disclaimer":
        "By providing your email address, you agree to its use for internal tracking purposes. We will not share your information with third parties. For more details, please see our ",
    "resources.downloadError": "An error occurred during download.",
    "resources.emailLabel": "Email",
    "resources.download": "Download",
    "resources.emailPlaceholder": "Enter your email to download",
    "resources.downloadDisclaimer":
        "*Please note: download will start automatically.",
    "resources.cancel": "Cancel",
    "resources.invalidEmail": "Please enter a valid email address.",
    "resources.downloadPopupTitle": "Requesting resource: ",
    "resources.downloadPopupDescription":
        "Your download will start automatically. If it doesn't, please click the button below.",

    // Privacy page // Privacy page // Privacy page // Privacy page // Privacy page // Privacy page
    "privacy.title": "Privacy Policy",

    "privacy.h1": "I. Preliminary Notes",
    "privacy.t1.a":
        "Protecting your personal data is our highest priority. We only collect information that is strictly necessary for the use of our website, ",
    "privacy.t1.b": "www.lexconnect.ch ",
    "privacy.t1.c":
        "(the “Website”), and for delivering our products and services. All data collected is handled with the utmost care",
    "privacy.t1.d":
        "We comply with the Swiss Federal Data Protection Act (“DPA”) and, where applicable, the EU General Data Protection Regulation (“GDPR”) in our collection and processing of personal data.",
    "privacy.t1.e":
        "This Privacy Policy (the “Policy”) details our practices regarding the collection, storage, use, and disclosure of personal data through our Website.",
    "privacy.t1.f":
        "Please read this Policy carefully. If you do not agree with its terms, we ask that you refrain from using the Website and our services.",

    "privacy.h2": "II. Scope of the Policy",
    "privacy.t2.a":
        "This Policy applies to the use of the Website, operated by Alexandra Dallüge, and to all personal data collected through the Website.",
    "privacy.t2.b":
        "Please be aware that our Website may include links to third-party websites, which are governed by their own privacy policies. We are not responsible for how these third parties collect, use, or protect your personal data. We recommend that you review the privacy policies of any third-party sites before providing them with your personal information.",

    "privacy.h3": "III. Types of Data Collected",
    "privacy.t3.a":
        "When you visit the Website, our web server automatically records certain details about your visit, including your IP address, the referring website, your browser type, and the pages you view, along with the date and duration of your visit.",
    "privacy.t3.b":
        "Additionally, we collect personal data that you provide directly through the Website, such as when you fill out a contact form, download a resource, schedule an appointment, or make a purchase. The types of data collected include:",
    "privacy.t3.p1": "Identity Data: Your first and last names.",
    "privacy.t3.p2":
        "Contact Data: Addresses (billing address, email address) and phone numbers.",
    "privacy.t3.p3":
        "Financial Data: Bank details, payment card information, and payment history.",
    "privacy.t3.p4":
        "Service Data: Information about the services you have received or provided.",
    "privacy.t3.p5":
        "Professional Information: Details related to your job functions and professional activities.",
    "privacy.t3.p6":
        "Other Information: Any additional information shared during our interactions.",
    "privacy.t3.c":
        "We are committed to handling this information confidentially and using it solely for the purposes for which it was collected.",
    "privacy.t3.d":
        "We do not process sensitive data as defined in Article 5(c) of the DPA.",

    "privacy.h4": "IV. Purpose of Data Processing",
    "privacy.t4.a":
        "When you use our services, browse the Website, or contact us, we collect and process your personal data for the following purposes:",
    "privacy.t4.p1":
        "Communication: To engage with you and verify your identity.",
    "privacy.t4.p2":
        "Service Provision: To prepare, conclude, and document contracts necessary for delivering our services, as well as for billing purposes.",
    "privacy.t4.p3":
        "Order Management: To handle and process orders placed on the Website.",
    "privacy.t4.p4":
        "Free Resource Distribution: When you download free resources from our Website, we collect your email address to monitor interest in our offerings.",
    "privacy.t4.p5":
        "Legitimate Interests: To enhance our services and prevent fraudulent activities.",
    "privacy.t4.p6":
        "Legal Compliance: To fulfill legal obligations, including those related to accounting, taxation, and data protection.",
    "privacy.t4.b":
        "The legal bases for processing your data are primarily your consent, the performance of a contract, our legitimate interests, and compliance with a legal obligation, in accordance with Article 6(1) of the GDPR.",

    "privacy.h5": "V. Data Transfer to Third Parties",
    "privacy.t5.a":
        "We are committed to protecting your data and do not sell it to third parties. However, to effectively provide our products and services, we may need to share certain information with partners or external service providers.",
    "privacy.t5.b": "We may disclose your data to the following entities:",
    "privacy.t5.p1":
        "IT Service Provider: An independent provider responsible for maintaining our server may have limited access to your data during technical interventions. We ensure that the provider adheres to stringent confidentiality and data security standards.",
    "privacy.t5.p2": "External Service Providers:",
    "privacy.t5.p2.1":
        "Calendly: For managing online appointments. You can review their privacy policy here.",
    "privacy.t5.p2.2":
        "Stripe: For processing online payments. You can review their privacy policy here.",
    "privacy.t5.p3":
        "Legal Authorities: We may disclose your data to relevant authorities to comply with legal or regulatory requirements or to protect our legal rights.",
    "privacy.t5.c":
        "If we need to transfer your data to a country outside Switzerland or the EU/EEA that does not offer adequate data protection, we will implement appropriate safeguards to ensure your data remains protected.",

    "privacy.h6": "VI. Social Media",
    "privacy.t6.a":
        "We operate pages and profiles on social media platforms and other third-party sites.",
    "privacy.t6.b":
        "When you interact with us on these platforms—by commenting on our posts, liking our content, or engaging with us in other ways—we collect the data you provide directly. We also receive data from the platforms themselves, such as usage statistics.",
    "privacy.t6.c":
        "These platform providers may analyze your interactions and combine this data with other information they have about you for purposes such as marketing, market research, and managing their platforms. They are responsible for handling your data in accordance with their own privacy policies.",
    "privacy.t6.d": "We currently use the following platforms:",
    "privacy.t6.p1": "LinkedIn: Privacy Policy",
    "privacy.t6.p2": "Instagram: Privacy Policy",
    "privacy.t6.e":
        "When you click on a link to one of our social media profiles, a direct connection is made between your browser and the social network's server. This allows the social network to receive information about your visit to our Website, including your IP address. If you are logged into your account on the social network at the time, it may link your visit to our Website with your profile.",
    "privacy.t6.f":
        "Please review the privacy policies of these social networks to understand how they use your data. Note that we have no control over their practices and are not responsible for them.",

    "privacy.h7": "VII. Data Security",
    "privacy.t7.a":
        "Our server is hosted in Germany by a provider known for its reliability and security. This provider is solely responsible for hosting the server. The server has been specially designed to meet our needs by a trusted independent technical partner. This partner is responsible for the development, technical management, and maintenance of our Website, all under our direct supervision. We collaborate closely with this partner to ensure the protection of your personal data, in strict adherence to our Policy. We maintain rigorous control over the server, the Website, and your data.",
    "privacy.t7.b":
        "We have implemented appropriate security measures to protect your personal data from loss, unauthorized access, misuse, disclosure, destruction, or alteration.",
    "privacy.t7.c":
        "While we apply industry best practices to safeguard your data, it is important to acknowledge that transmitting information over the Internet inherently involves risks. Therefore, we cannot guarantee absolute security for information transmitted online.",

    "privacy.h8": "VIII. Data Retention",
    "privacy.t8.a":
        "We retain your personal data for as long as necessary to fulfill the purposes for which it was collected and, in any case, for a maximum period of 10 years, in accordance with Swiss accounting and tax obligations.",
    "privacy.t8.b":
        "When your personal data is no longer needed to achieve these purposes, we will either delete or anonymize it, unless legal or regulatory obligations require us to retain it for a longer period.",

    "privacy.h9": "IX. Your Rights",
    "privacy.t9.a":
        "Under applicable data protection laws, you have the following rights concerning the processing of your personal data:",
    "privacy.t9.p1":
        "Right of Access: You have the right to request information about the personal data we process about you, at no cost.",
    "privacy.t9.p2":
        "Right to Rectification: You can review and request corrections to any inaccurate or incomplete personal data we hold.",
    "privacy.t9.p3":
        "Right to Erasure: You can request the deletion of your personal data in certain situations, such as when the data is no longer needed for the purposes for which it was collected or if you withdraw your consent.",
    "privacy.t9.p4":
        "Right to Object: You can object to the processing of your personal data, including for direct marketing purposes such as email marketing.",
    "privacy.t9.p5":
        "Right to Data Portability: You can request that your personal data be provided to you in a commonly used, machine-readable format, or transferred to another data controller.",
    "privacy.t9.p6":
        "Right to Restriction: You can request the restriction of processing your personal data in specific circumstances, such as when you dispute the accuracy of the data.",
    "privacy.t9.p7":
        "Right to Withdraw Consent: You can withdraw your consent to the processing of your data at any time. This withdrawal does not affect the legality of processing based on consent before its withdrawal.",
    "privacy.t9.p8":
        "Right to Lodge a Complaint: You have the right to file a complaint with a competent supervisory authority if you believe that your personal data is not being processed in compliance with applicable laws.",
    "privacy.t9.b":
        "To exercise these rights, please contact us by email at: EMAIL. We are committed to addressing your request promptly and in accordance with legal requirements.",

    "privacy.h10": "X. Cookies",
    "privacy.t10.a":
        "Our Website uses cookies and similar technologies, such as web beacons and pixels, to enhance your experience, enable certain features, and ensure security. Cookies are small data files stored on your device while you browse our Website.",
    "privacy.t10.b": "We use the following types of cookies:",
    "privacy.t10.p1":
        "Strictly Necessary Cookies: These cookies are essential for the basic operation of the Website. They enable functionalities such as navigation and form submissions. Since these cookies are crucial for the Website to function properly, they do not require your consent and do not collect personal data.",
    "privacy.t10.p2":
        "Functional Cookies: These cookies remember your preferences to improve your experience by personalizing the Website according to your needs, such as your selected language.",
    "privacy.t10.p3":
        "Third-Party Cookies: Cookies from third parties, such as Calendly for appointment scheduling and Stripe for payment processing, may be placed on your device. Your consent is required for these cookies. You can manage your preferences by adjusting the cookie settings on our Website or changing your browser settings.",
    "privacy.t10.c":
        "We do not use cookies for targeted advertising or in-depth user behavior analysis.",
    "privacy.t10.d":
        "You can still access most features of our Website if you choose to reject non-essential cookies. However, some features may not function optimally.",

    "privacy.h11": "XI. Data Protection Contact",
    "privacy.t11.a": "Alexandra Dallüge",
    "privacy.t11.b": "13, Chemin de la Moraine, 1008 Prilly",
    "privacy.t11.c": "Email: EMAIL HERE",
    "privacy.t11.d":
        "For inquiries about our products or services, as well as questions regarding data protection or to exercise your rights under this Policy, please contact us at: EMAIL HERE",

    "privacy.h12": "XII. Changes to the Policy",
    "privacy.t12.a":
        "This Policy does not constitute a contractual agreement. We reserve the right to amend or update it at any time. We encourage you to review this page periodically to stay informed of any changes. The most current version of the Policy will be available on our Website and will supersede all previous versions.",

    "privacy.h13": "XIII. Disclaimer",
    "privacy.t13.a": "External links",
    "privacy.t13.b":
        "Our Website may contain links to third-party websites. We have no control over these external sites and are not responsible for their content, privacy practices, or policies. The inclusion of links does not imply endorsement or approval of the information or services offered on these sites.",
    "privacy.t13.c": "Copyright",
    "privacy.t13.d":
        "The content on the Website is protected by copyright law. Unauthorized copying, reproduction, distribution, or transmission of any content produced by the Website is prohibited without our explicit written permission.",
    "privacy.t13.e": "Accuracy of Information",
    "privacy.t13.f":
        "While we strive to maintain accurate and up-to-date information on the Website, we make no warranties regarding the completeness or accuracy of the information provided. We accept no liability for any damages resulting from the use or inability to use the information on the Website.",

    "privacy.h14": "XIV. Governing Law and Disputes",
    "privacy.t14.a":
        "This Privacy Policy, as well as the use of the Website, is governed by Swiss law. Any disputes arising from this Privacy Policy or the use of the Website shall be exclusively resolved by the Swiss courts.",
    "privacy.t14.b":
        "These provisions are subject to any mandatory legal requirements in the Customer’s country of residence. In the event of a conflict between this Privacy Policy and local legal requirements, the latter shall prevail.",

    "privacy.h15": "XV. Languages",
    "privacy.t15.a":
        "The Website is available in both French and English. In the event of any discrepancy or ambiguity between the different language versions of the Website’s content or the Privacy Policy, the French version shall prevail and be deemed authoritative.",

    "privacy.lastUpdated": "Last Updated: September 30, 2024",

    // Terms page
    "terms.title": "Terms and Conditions of Sale",
    "terms.h1": "Article 1. Preamble",
    "terms.t1.a":
        "These General Terms and Conditions of Sale (hereinafter referred to as the “Terms and Conditions”) govern all orders placed via the website ",
    "terms.t1.b": "www.lexconnect.ch ",
    "terms.t1.c":
        "(hereinafter referred to as the “Website” or the “Online Store”) operated by:",
    "terms.t1.d":
        "Alexandra Dallüge (hereinafter referred to as “Alexandra” or “We”)",
    "terms.t1.e": "Chemin de la Moraine, 13",
    "terms.t1.f": "1008 Prilly, Switzerland",
    "terms.t1.g": "Email: EMAIL HERE",
    "terms.t1.h":
        "Alexandra is an independent legal advisor not registered with the bar. It is important to note that Alexandra cannot represent clients in court and does not provide services reserved for licensed lawyers. Her services do not replace the advice and support of a lawyer when needed or recommended.",
    "terms.t1.i":
        "Any specific agreements made with the customer (hereinafter referred to as the “Customer”) shall take precedence over these Terms and Conditions. Terms proposed by the Customer that differ from or conflict with these Terms and Conditions will not be recognized.",
    "terms.t1.j":
        "Alexandra reserves the right to modify these Terms and Conditions at any time. The applicable Terms and Conditions are those in effect on the Website at the time of the order.",
    "terms.t1.k":
        "By accessing the services and products offered on the Website, the Customer acknowledges having read and understood these Terms and Conditions. Confirmation of having read and approved these Terms and Conditions is required before finalizing the order.",

    "terms.h2": "Article 2. Digital products",
    "terms.t2.a":
        "The Online Store may offer a variety of digital products, including both free and paid products (hereinafter referred to as the “Digital Products” or the “Digital Product”).",
    "terms.t2.b":
        "The paid Digital Products primarily consist of legal document templates intended for entrepreneurs and freelances in Switzerland. These templates are provided in Word format and can be modified and customized according to the specific needs of the Customer.",
    "terms.t2.c":
        "The Digital Products available on the Online Store may be subject to changes. Alexandra reserves the right to modify, at any time and without prior notice, the Digital Products available on the Website, as well as related content such as prices and descriptions. Images and representations of the Digital Products are provided for informational purposes only and do not bind Alexandra in any way.",

    "terms.h3": "Article 3. Order",
    "terms.t3.a":
        "Before placing an order, the Customer must verify that the selected Digital Product meets their needs. The order constitutes a firm and binding contractual commitment (hereinafter referred to as the “Order”).",
    "terms.t3.b": "To place an Order, the Customer must:",
    "terms.t3.p1":
        "Select the desired Digital Product and confirm their choice,",
    "terms.t3.p2":
        "Proceed to the payment page where they must read and accept the Terms and Conditions and complete the payment.",
    "terms.t3.c":
        "Once payment has been processed, the Digital Product will be made available for download through a secure link. This link will be sent to the Customer via email to the address provided, and it will be valid for 24 hours and can be used only once. Alexandra may, at her discretion, provide a new download link if requested by the Customer.",
    "terms.t3.d":
        "The Customer agrees to provide valid contact information when placing the Order. Alexandra cannot be held responsible for difficulties in executing the Order resulting from incorrect information provided by the Customer.",
    "terms.t3.e":
        "Additionally, the Customer is responsible for ensuring that their hardware and software are compatible with the Digital Product to ensure proper functionality. We do not guarantee compatibility with the Customer’s specific systems or software.",

    "terms.h4": "Article 4. Price",
    "terms.t4.a":
        "The applicable price is the one indicated on the Online Store at the time of the Order. Alexandra reserves the right to change the prices of the Digital Products at any time.",
    "terms.t4.b":
        "All prices are listed in CHF (Swiss francs). The total price of your order, including all applicable taxes and fees, will be confirmed at the end of the ordering process on the Website.",

    "terms.h5": "Article 5. Payment Terms",
    "terms.t5.a":
        "Orders for Digital Products must be paid for immediately at the time of ordering. Payment can be made using the payment methods available on the Website, including, but not limited to, Stripe.",
    "terms.t5.b":
        "By proceeding with payment, the Customer authorizes the processing of transactions by Stripe, a secure payment service provider.",
    "terms.t5.c":
        "Alexandra disclaims any responsibility for fees or additional charges imposed by Stripe, the Customer's credit card issuer, or their bank.",
    "terms.t5.d":
        "In the event of a payment rejection, Alexandra reserves the right to cancel the order and suspend the delivery of the Digital Products.",

    "terms.h6": "Article 6. Warranty",
    "terms.t6.a":
        "We strive to ensure that the Digital Products we provide are accurate and function as expected at the time of purchase.",
    "terms.t6.b":
        "Upon receiving the Digital Product, the Customer should promptly verify its conformity and absence of defects. Any claims regarding defects or damages must be reported to Alexandra via email at EMAILHERE within 7 business days of receipt. After this period, the Digital Product will be considered accepted, unless there are hidden defects.",
    "terms.t6.c":
        "If a defect or damage is identified, we will provide a new functional version of the Digital Product at no additional cost, provided the defect was not caused by modifications made by the Customer. The new version will be sent via email. Please note that we do not consider claims related to subjective aspects of the content.",

    "terms.h7": "Article 7. Liability",
    "terms.t7.a":
        "Our total liability under these Terms and Conditions is limited to the amount paid for the relevant Digital Product. We are not liable for any indirect, special, or consequential damages resulting from the use of our Digital Products, including but not limited to loss of profit, business interruption, or data loss.",
    "terms.t7.b":
        "Furthermore, we shall not be held liable for any failure to meet our obligations under these Terms and Conditions if such failure is caused by events of force majeure as defined by applicable law, including but not limited to natural disasters, wars, or network outages.",
    "terms.t7.c":
        "The Digital Products are provided 'as is' and do not substitute for legal counsel or advice from a licensed lawyer. Alexandra cannot be held responsible for the use of these Digital Products or for any direct or indirect consequences arising from such use.",
    "terms.t7.d":
        "This limitation of liability does not apply in cases of gross negligence or intentional misconduct on our part, nor for personal injury or any other situation where Swiss law prohibits such a limitation.",

    "terms.h8": "Article 8. Right of Withdrawal and Refund",
    "terms.t8.a":
        "Due to the intangible and downloadable nature of Digital Products, they are exempt from the right of withdrawal. Consequently, once payment is processed and the Digital Product is made available, the Customer has no right of withdrawal, cancellation, or refund.",

    "terms.h9": "Article 9.  Data Protection",
    "terms.t9.a":
        "The protection of the Customer’s personal data is our priority. The Privacy Policy, available on the Website, forms an integral part of these Terms and Conditions.",
    "terms.t9.b":
        "By accepting these Terms and Conditions, the Customer confirms that they have read and understood the Privacy Policy and consent to the collection and processing of their personal data in accordance with it.",

    "terms.h10": "Article 10. Intellectual Property and Usage Rights",
    "terms.t10.a":
        "The Digital Products and the Website are protected by copyright under Swiss law. All rights not expressly granted to the Customer are reserved by Alexandra.",
    "terms.t10.b":
        "Any reproduction, modification, distribution, or other use, in whole or in part, of the content published on the Website is prohibited without the prior written consent of Alexandra.",
    "terms.t10.c":
        "Upon payment, the Customer is granted a non-exclusive, non-transferable, and non-sublicensable right to use the Digital Products available on the Online Store. This right is limited to the Customer's personal or professional use. The Customer may modify and adapt the paid Digital Products for personal or professional purposes but may not resell, redistribute, or use them for commercial purposes beyond their intended use.",
    "terms.t10.d":
        "Digital Products provided free of charge on the Website are also intended solely for personal use. The Customer agrees not to copy, modify, distribute, or share these resources in any manner other than as permitted for personal use. Alexandra reserves the right to modify or remove these free resources at any time without notice.",

    "terms.h11": "Article 11. Severability Clause",
    "terms.t11.a":
        "If any provision of these Terms and Conditions is found to be invalid, void, or unenforceable under applicable law, such invalidity shall not affect the validity of the remaining provisions, which shall remain in full force and effect.",
    "terms.t11.b":
        "In such cases, Alexandra will replace the invalid provision with a valid provision that closely reflects the original intent of the invalid provision.",

    "terms.h12": "Article 12. Governing Law and Dispute Resolution",
    "terms.t12.a":
        "These Terms and Conditions, as well as all legal relationships between Alexandra and the Customer, shall be governed by Swiss law.",
    "terms.t12.b":
        "In the event of a dispute, the parties shall first seek to resolve it amicably. If an agreement cannot be reached, the dispute shall be submitted to the exclusive jurisdiction of the Swiss courts.",
    "terms.t12.c":
        "These provisions are subject to any mandatory legal requirements in the Customer’s country of residence. In the event of a conflict between these Terms and Conditions and local legal requirements, the latter shall prevail.",

    "terms.h13": "Article 13. Languages",
    "terms.t13.a":
        "The Website is available in both French and English. In the event of any discrepancy or ambiguity between the different language versions of the Website’s content or these Terms and Conditions, the French version shall prevail and be deemed authoritative.",

    "terms.lastUpdated": "Last updated: September 30th, 2024",

    // Legal Notices page // Legal notices page
    "legalnotice.title": "Legal Notices",
    "legalnotice.h1": "Site Name",
    "legalnotice.t1.a": "www.lexconnect.ch (the “Website”)",

    "legalnotice.h2": "Owner and Publisher",
    "legalnotice.t2.a": "Alexandra Dallüge",
    "legalnotice.t2.b": "13, Chemin de la Moraine, CH-1008 Prilly, Switzerland",
    "legalnotice.t2.c": "Contact: EMAIL HERE",
    "legalnotice.t2.d": "IDE Number: CHE 349.209.011",

    "legalnotice.h3": "Cloud Hosting Provider",
    "legalnotice.t3.a": "Hetzner Online GmbH",
    "legalnotice.t3.b": "Industriestr. 25, 91710 Gunzenhausen, Germany",
    "legalnotice.t3.c": "Tel.: +49 (0)9831 505-0",
    "legalnotice.t3.d": "Email: EMAIL HERE",

    "legalnotice.h4": "Legal Status",
    "legalnotice.t4.a":
        "Alexandra Dallüge, the owner of this website and provider of the services offered, is an independent legal advisor not registered with the bar. As such, she provides legal services based on her expertise. However, she cannot represent clients in court and does not provide services reserved for licensed lawyers. The recommendations and legal services provided aim to assist clients with their legal needs but do not replace the advice or services of a lawyer.",

    "legalnotice.h5": "Intellectual Property",
    "legalnotice.t5.a":
        "The content of this Website, including text, images, logos and other elements, is protected by copyright. Any unauthorized copying, reproduction, distribution, or transmission of all or part of the Website’s content is prohibited without prior written consent.",

    "legalnotice.h6": "Liability",
    "legalnotice.t6.a":
        "You agree to provide accurate and current information when using our services.",
    "legalnotice.t6.b":
        "We are not responsible for the content of external websites accessible via hyperlinks from this Website. While we make every effort to ensure the accuracy and security of the Website, we cannot guarantee it will be free from errors, interruptions, or viruses.",
    "legalnotice.t6.c":
        "By using the Website, you acknowledge that you do so at your own risk. We cannot be held liable for any direct or indirect damages arising from your use of the Website.",
    "legalnotice.t6.d":
        "The content of this Website may be updated or removed at any time without prior notice. It is the responsibility of the user to regularly review these legal notices to stay informed of any changes.",

    "legalnotice.h7": "Data Protection",
    "legalnotice.t7.a":
        "For detailed information on how we collect, use, store, and protect your personal data, as well as your data protection rights, please refer to our Privacy Policy. This policy also includes information about our use of cookies.",

    "legalnotice.h8": "Applicable Law and Jurisdiction",
    "legalnotice.t8.a":
        "These legal notices are governed by Swiss law. In the event of any dispute, the courts of the canton of Vaud shall have exclusive jurisdiction.",

    "legalnotice.h9": "Languages",
    "legalnotice.t9.a":
        "The Website is available in both French and English. In the event of any discrepancies or ambiguities between the different language versions of these legal notices or any other content on the Website, the French version shall prevail and be binding.",

    "legalnotice.lastUpdated": "Last updated: September 28th, 2024",
};

export default enMessages;
