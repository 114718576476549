import React from "react";
import { FormattedMessage } from "react-intl";

function Privacy() {
    return (
        <div className="flex-grow">
            <main className="flex items-stretch">
                <section
                    id="about"
                    className="w-full bg-beige font-pt py-20 flex-grow"
                >
                    <div className="container mx-auto px-4 flex flex-col md:flex-row items-start justify-center">
                        <div className="w-full md:w-2/3 text-center md:text-left md:pl-8 font-marcellus">
                            <h2 className="font-bold leading-tight mb-6">
                                <FormattedMessage id="privacy.title" />
                            </h2>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h1" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t1.a" />
                                <a href="https://www.lexconnect.ch">
                                    <FormattedMessage id="privacy.t1.b" />
                                </a>
                                <FormattedMessage id="privacy.t1.c" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t1.d" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t1.e" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t1.f" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h2" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t2.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t2.b" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h3" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t3.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t3.b" />
                            </p>
                            <ul className="list-disc list-inside">
                                <li>
                                    <FormattedMessage id="privacy.t3.p1" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t3.p2" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t3.p3" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t3.p4" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t3.p5" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t3.p6" />
                                </li>
                            </ul>
                            <p className="mb-4 mt-4">
                                <FormattedMessage id="privacy.t3.c" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t3.d" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h4" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t4.a" />
                            </p>
                            <ul className="list-disc list-inside">
                                <li>
                                    <FormattedMessage id="privacy.t4.p1" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t4.p2" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t4.p3" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t4.p4" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t4.p5" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t4.p6" />
                                </li>
                            </ul>
                            <p className="mb-4 mt-4">
                                <FormattedMessage id="privacy.t4.b" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h5" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t5.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t5.b" />
                            </p>
                            <ul className="list-disc list-inside">
                                <li>
                                    <FormattedMessage id="privacy.t5.p1" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t5.p2" />
                                    <ul className="list-disc list-inside ml-4 mt-2">
                                        <li>
                                            <FormattedMessage id="privacy.t5.p2.1" />
                                        </li>
                                        <li>
                                            <FormattedMessage id="privacy.t5.p2.2" />
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t5.p3" />
                                </li>
                            </ul>
                            <p className="mt-4 mb-4">
                                <FormattedMessage id="privacy.t5.c" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h6" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t6.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t6.b" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t6.c" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t6.d" />
                            </p>
                            <ul className="list-disc list-inside">
                                <li>
                                    <FormattedMessage id="privacy.t6.p1" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t6.p2" />
                                </li>
                            </ul>
                            <p className="mt-4 mb-4">
                                <FormattedMessage id="privacy.t6.e" />
                            </p>
                            <p className="mt-4 mb-4">
                                <FormattedMessage id="privacy.t6.f" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h7" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t7.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t7.b" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t7.c" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h8" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t8.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t8.b" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h9" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t9.a" />
                            </p>
                            <ul className="list-disc list-inside">
                                <li>
                                    <FormattedMessage id="privacy.t9.p1" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t9.p2" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t9.p3" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t9.p4" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t9.p5" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t9.p6" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t9.p7" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t9.p8" />
                                </li>
                            </ul>
                            <p className="mt-4 mb-4">
                                <FormattedMessage id="privacy.t9.b" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h10" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t10.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t10.b" />
                            </p>
                            <ul className="list-disc list-inside">
                                <li>
                                    <FormattedMessage id="privacy.t10.p1" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t10.p2" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.t10.p3" />
                                </li>
                            </ul>
                            <p className="mt-4 mb-4">
                                <FormattedMessage id="privacy.t10.c" />
                            </p>
                            <p className="mt-4 mb-4">
                                <FormattedMessage id="privacy.t10.d" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h11" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t11.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t11.b" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t11.c" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t11.d" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h12" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t12.a" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h13" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t13.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t13.b" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t13.c" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t13.d" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t13.e" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t13.f" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h14" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t14.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t14.b" />
                            </p>
                            <h3 className="mb-4">
                                <FormattedMessage id="privacy.h15" />
                            </h3>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.t15.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.lastUpdated" />
                            </p>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default Privacy;
