import React, { useState } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { FormattedMessage } from "react-intl";

const CookieConsentBanner = () => {
    const [showDetails, setShowDetails] = useState(false);

    const handleAccept = () => {
        // You can add more specific cookie setting logic here as needed
        console.log("Cookies accepted");
    };

    const handleDecline = () => {
        // You can add more specific cookie removal logic here as needed
        console.log("Cookies declined");
    };

    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            cookieName="LexConnectCookieConsent"
            style={{ background: "#000000" }}
            buttonStyle={{
                color: "#000000",
                backgroundColor: "#ede6d6",
                fontSize: "13px",
                borderRadius: "9999px", // Full rounded border
                padding: "8px 16px", // Added padding for better appearance
            }}
            declineButtonStyle={{
                color: "#ede6d6",
                backgroundColor: "#690f39",
                fontSize: "13px",
                borderRadius: "9999px", // Full rounded border
                padding: "8px 16px", // Added padding for better appearance
            }}
            expires={150}
            onAccept={handleAccept}
            onDecline={handleDecline}
            enableDeclineButton
        >
            <div>
                <FormattedMessage id="cookie.consent" />
                <button onClick={() => setShowDetails(!showDetails)}>
                    {showDetails ? "Hide Details" : "Show Details"}
                </button>
            </div>
            {showDetails && (
                <div>
                    <p>We use cookies to:</p>
                    <ul>
                        <li>Ensure the website functions properly</li>
                        <li>Remember your preferences</li>
                        <li>
                            Analyze how you use our site to improve our services
                        </li>
                    </ul>
                    <p>For more information, please see our Privacy Policy.</p>
                </div>
            )}
        </CookieConsent>
    );
};

export default CookieConsentBanner;
