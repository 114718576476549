import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import "../index.css";

import { API_URL } from "../config";

function Contact() {
    // Initialize form state with useState hook
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        service: [], // Array for multiple service selections
        honeypot: "", // Hidden field to catch spam bots
        acceptTerms: false, // Checkbox for terms acceptance
    });

    // State variables for error handling and UI feedback
    const [errorMessage, setErrorMessage] = useState("");
    const [termsError, setTermsError] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [emailError, setEmailError] = useState("");

    // Handle changes in form inputs
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? name === "acceptTerms"
                        ? checked
                        : checked
                        ? [...prevState.service, value]
                        : prevState.service.filter((item) => item !== value)
                    : value,
        }));
        setErrorMessage(""); // Clear error message on input change
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Reset error messages
        setErrorMessage("");
        setTermsError("");
        setEmailError("");

        // Check if honeypot field is filled (spam detection)
        if (formData.honeypot) {
            setErrorMessage(<FormattedMessage id="contact.errorSpam" />);
            return;
        }

        // Destructure form data for validation
        const { firstName, lastName, email, message, service, acceptTerms } =
            formData;
        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

        let hasError = false;

        // Validate required fields
        if (
            !firstName ||
            !lastName ||
            !email ||
            !message ||
            service.length === 0
        ) {
            setErrorMessage(<FormattedMessage id="contact.errorAllFields" />);
            hasError = true;
        }

        // Validate email format
        if (!isEmailValid) {
            setEmailError(<FormattedMessage id="contact.errorInvalidEmail" />);
            hasError = true;
        }

        // Check terms acceptance
        if (!acceptTerms) {
            setTermsError(<FormattedMessage id="contact.errorTerms" />);
            hasError = true;
        }

        if (hasError) return;

        // Submit form data if validation passes
        try {
            const response = await axios.post(
                `${API_URL}/send-email`,
                formData
            );
            if (response.data.success) {
                // Reset form and show success message
                setFormData({
                    firstName: "",
                    lastName: "",
                    email: "",
                    message: "",
                    service: [],
                    honeypot: "",
                    acceptTerms: false,
                });
                setErrorMessage("");
                setShowConfirmation(true);
            } else {
                throw new Error("Failed to send email");
            }
        } catch (error) {
            console.error("Error sending email:", error);
            // Log detailed error information
            if (error.response) {
                console.error("Response data:", error.response.data);
                console.error("Response status:", error.response.status);
                console.error("Response headers:", error.response.headers);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Error message:", error.message);
            }
            setErrorMessage(<FormattedMessage id="contact.errorSending" />);
        }
    };

    // Close confirmation modal
    const closeConfirmation = () => {
        setShowConfirmation(false);
    };

    // Render contact form
    return (
        <section id="contact" className="py-16 relative bg-beige">
            <div className="container mx-auto relative z-10">
                <h2 className="text-center">
                    <FormattedMessage id="contact.title" />
                </h2>
                <form
                    onSubmit={handleSubmit}
                    className="max-w-3xl mx-auto bg-beige p-8 grid grid-cols-1 gap-6"
                    noValidate
                >
                    <div className="grid grid-cols-2 gap-6 mb-4">
                        <div>
                            <label htmlFor="firstName" className="block mb-2">
                                <FormattedMessage id="contact.firstName" />
                            </label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-black"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="lastName" className="block mb-2">
                                <FormattedMessage id="contact.lastName" />
                            </label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-black"
                                required
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block mb-2">
                            <FormattedMessage id="contact.email" />
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-black"
                            required
                        />
                        {emailError && (
                            <div className="text-red-500 text-sm mt-1">
                                {emailError}
                            </div>
                        )}
                    </div>
                    <div className="mb-4">
                        {" "}
                        {/* Moved service selection here */}
                        <label className="block mb-2">
                            <FormattedMessage id="contact.service" />
                        </label>
                        <div className="flex space-x-4">
                            {" "}
                            {/* Use flexbox for inline layout */}
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    name="service"
                                    value="legal"
                                    checked={formData.service.includes("legal")}
                                    onChange={handleChange}
                                    className="custom-checkbox" // Updated class
                                />
                                <span>
                                    <FormattedMessage id="contact.legal" />
                                </span>
                            </div>
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    name="service"
                                    value="translation"
                                    checked={formData.service.includes(
                                        "translation"
                                    )}
                                    onChange={handleChange}
                                    className="custom-checkbox" // Updated class
                                />
                                <span>
                                    <FormattedMessage id="contact.translation" />
                                </span>
                            </div>
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    name="service"
                                    value="other"
                                    checked={formData.service.includes("other")}
                                    onChange={handleChange}
                                    className="custom-checkbox" // Updated class
                                />
                                <span>
                                    <FormattedMessage id="contact.other" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        {" "}
                        {/* Ensure the message box is full width */}
                        <label
                            htmlFor="message"
                            className="block mb-2 text-black"
                        >
                            <FormattedMessage id="contact.message" />
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            rows="6" // Increased rows for a bigger message box
                            className="w-full px-3 py-2 border border-black resize-none" // Added resize-none to prevent resizing
                        ></textarea>
                    </div>
                    <div className="mb-4">
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                id="acceptTerms"
                                name="acceptTerms"
                                checked={formData.acceptTerms}
                                onChange={handleChange}
                                className="custom-checkbox mr-2"
                                required
                            />
                            <label htmlFor="acceptTerms" className="text-sm">
                                <FormattedMessage id="contact.acceptTerms" />
                                <a href="/privacy">
                                    <FormattedMessage id="contact.acceptTerms.link" />
                                </a>
                            </label>
                        </div>
                        {termsError && (
                            <div className="text-red-500 text-xs font-merriweather mt-1">
                                {termsError}
                            </div>
                        )}
                    </div>
                    {errorMessage && (
                        <div className="text-red-500 mb-4">{errorMessage}</div>
                    )}{" "}
                    {/* Display error message */}
                    <button
                        type="submit"
                        className="btn-primary ml-auto block px-6 py-2"
                    >
                        <FormattedMessage id="contact.submit" />
                    </button>
                </form>
            </div>

            {/* Confirmation modal */}
            {showConfirmation && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
                        <h3 className="mb-4">
                            <FormattedMessage id="contact.confirmationTitle" />
                        </h3>
                        <p className="mb-6">
                            <FormattedMessage id="contact.confirmationMessage" />
                        </p>
                        <button
                            onClick={closeConfirmation}
                            className="btn-primary w-full"
                        >
                            <FormattedMessage id="contact.confirmationClose" />
                        </button>
                    </div>
                </div>
            )}
        </section>
    );
}

export default Contact;
