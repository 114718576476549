import React, { useContext, useState, useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { LanguageContext } from "../contexts/LanguageContext";
import { Link, useLocation } from "react-router-dom";

// Header component for the application
function Header() {
    // Access language context for localization
    const { locale, setLocale } = useContext(LanguageContext);
    // State to manage mobile menu visibility
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // Get current location for navigation highlighting
    const location = useLocation();

    // Ref to store timeout for services menu (unused in this snippet)
    const servicesTimeoutRef = useRef(null);

    // Toggle mobile menu visibility
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    // Close mobile menu when location changes
    useEffect(() => {
        setIsMenuOpen(false);
    }, [location]);

    // Cleanup effect to clear any lingering timeouts
    useEffect(() => {
        return () => {
            if (servicesTimeoutRef.current) {
                clearTimeout(servicesTimeoutRef.current);
            }
        };
    }, []);

    return (
        <header className="bg-burgundy text-beige py-8 sm:py-10 lg:py-12 px-4 sm:px-6 lg:px-8 sticky top-0 w-full z-50">
            <div className="container mx-auto flex flex-wrap justify-between items-center">
                {/* Logo/Home link */}
                <Link
                    to="/"
                    className="text-2xl sm:text-3xl lg:text-4xl hover:text-gray-200 transition-colors duration-200 pl-4"
                >
                    <FormattedMessage id="header.title" />
                    <span className="animate-flash">.</span>
                </Link>
                {/* Mobile menu toggle button */}
                <button
                    onClick={toggleMenu}
                    className="block min-[816px]:hidden focus:outline-none"
                    aria-label="Toggle menu"
                >
                    {/* SVG icon for menu toggle */}
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16m-7 6h7"
                        />
                    </svg>
                </button>
                {/* Navigation menu */}
                <nav
                    className={`w-full min-[816px]:w-auto ${
                        isMenuOpen ? "block" : "hidden"
                    } min-[816px]:block mt-4 min-[816px]:mt-0 transition-all duration-300 ease-in-out`}
                >
                    <ul className="flex flex-col min-[816px]:flex-row min-[816px]:items-center min-[816px]:space-x-2 lg:space-x-4 space-y-1 min-[816px]:space-y-0">
                        {/* Navigation items */}
                        {[
                            "home",
                            "about",
                            "legal",
                            "translation",
                            "shop",
                            "resources",
                            "contactme",
                        ].map((item) => (
                            <li key={item}>
                                <Link
                                    to={`/${item === "home" ? "" : item}`}
                                    className="hover-underline-animation block py-1 min-[816px]:py-0"
                                >
                                    <span className="text-base min-[816px]:text-sm lg:text-base xl:text-base font-medium">
                                        <FormattedMessage id={`nav.${item}`} />
                                    </span>
                                </Link>
                            </li>
                        ))}
                        {/* Language switcher */}
                        <li>
                            <div className="flex items-center space-x-2 text-sm mt-4 min-[816px]:mt-0">
                                {["fr", "en"].map((lang) => (
                                    <React.Fragment key={lang}>
                                        <button
                                            onClick={() => setLocale(lang)}
                                            className={`font-medium ${
                                                locale === lang
                                                    ? "text-white"
                                                    : "text-[#ede6c6] hover:text-white"
                                            } transition-colors duration-200`}
                                            aria-label={
                                                lang === "fr"
                                                    ? "Français"
                                                    : "English"
                                            }
                                        >
                                            {lang.toUpperCase()}
                                        </button>
                                        {/* Separator between language options */}
                                        {lang === "fr" && (
                                            <span className="text-gray-300">
                                                |
                                            </span>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;
