import React from "react";
import Hero from "./Hero";
import Work from "./Work";
import Contact from "./Contact";

const Home = () => {
    return (
        <div className="flex-grow">
            <main className="flex-grow">
                <Hero />
                <Work />
                <Contact />
            </main>
        </div>
    );
};

export default Home;
