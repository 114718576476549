import React, { useEffect, useRef } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import { LanguageProvider, useLanguage } from "./contexts/LanguageContext";
import { IntlProvider } from "react-intl";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Layout from "./components/Layout";
// Import all components used in the application
import Home from "./components/Home";
import Translation from "./components/Translation";
import Legal from "./components/Legal";
import About from "./components/About";
import Booking from "./components/Booking";
import Privacy from "./components/Privacy";
import TermsConditions from "./components/TermsConditions";
import Shop from "./components/Shop";
import Resources from "./components/Resources";
import Contactme from "./components/Contactme";
import CookieConsentBanner from "./components/CookieConsent";
import LegalNotices from "./components/LegalNotices";
import SuccessPage from "./components/SuccessPage";
import Download from "./components/Download";

// Main App component that sets up the language provider and router
function App() {
    return (
        <LanguageProvider>
            <LanguageWrapper>
                <Router>
                    <AppContent />
                    <CookieConsentBanner />
                </Router>
            </LanguageWrapper>
        </LanguageProvider>
    );
}

// AppContent component handles routing and page transitions
function AppContent() {
    const location = useLocation();
    const nodeRef = useRef(null);

    // Scroll to top of the page when route changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Layout>
            {/* SwitchTransition for smooth transitions between routes */}
            <SwitchTransition mode="out-in">
                <CSSTransition
                    key={location.pathname}
                    classNames="fade"
                    timeout={600}
                    nodeRef={nodeRef}
                >
                    <div ref={nodeRef}>
                        {/* Define routes for the application */}
                        <Routes location={location}>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/legal" element={<Legal />} />
                            <Route
                                path="/translation"
                                element={<Translation />}
                            />
                            <Route path="/shop" element={<Shop />} />
                            <Route path="/resources" element={<Resources />} />
                            <Route path="/booking" element={<Booking />} />
                            <Route
                                path="/termsconditions"
                                element={<TermsConditions />}
                            />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route
                                path="/legalnotices"
                                element={<LegalNotices />}
                            />
                            <Route path="/contactme" element={<Contactme />} />
                            <Route
                                path="/download/:token"
                                element={<Download />}
                            />
                            <Route path="/success" element={<SuccessPage />} />
                        </Routes>
                    </div>
                </CSSTransition>
            </SwitchTransition>
        </Layout>
    );
}

// LanguageWrapper component provides internationalization support
function LanguageWrapper({ children }) {
    const { locale, messages } = useLanguage();
    return (
        <IntlProvider messages={messages} locale={locale} defaultLocale="en">
            {children}
        </IntlProvider>
    );
}

export default App;
