import React, { createContext, useState, useContext, useEffect } from "react";
import enMessages from "./enMessages";
import frMessages from "./frMessages";

// Define available languages for the application
export const LANGUAGES = {
    EN: "en",
    FR: "fr",
};

// Create a context for managing language state
export const LanguageContext = createContext();

// LanguageProvider component to manage language state and provide it to child components
export const LanguageProvider = ({ children }) => {
    // Initialize language state from localStorage or default to English
    const [locale, setLocale] = useState(() => {
        return localStorage.getItem("language") || LANGUAGES.EN;
    });

    // Define messages for each supported language
    const messages = {
        [LANGUAGES.EN]: enMessages,
        [LANGUAGES.FR]: frMessages,
    };

    // Persist language choice in localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem("language", locale);
    }, [locale]);

    // Create the context value object
    const value = {
        locale,
        setLocale,
        messages: messages[locale],
    };

    // Provide the language context to child components
    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    );
};

// Custom hook to easily access the language context in components
export const useLanguage = () => useContext(LanguageContext);
