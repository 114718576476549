import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Input, Label } from "./ui/UIComponents";
import { useIntl } from "react-intl";
import freebieImage from "../assets/images/freebie.png";
import { API_URL } from "../config";

// Component for displaying and managing downloadable resources
function Resources() {
    const intl = useIntl();
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState("");

    // Array of downloadable products
    // Currently only one product, but structure allows for easy addition of more
    const products = [
        {
            id: 1,
            title: <FormattedMessage id="resources.title.1" />,
            image: freebieImage,
            description: <FormattedMessage id="resources.description.1" />,
            fileName: "Freebie.pdf",
        },
    ];

    // Handles the click on the download button for a specific file
    const handleDownloadClick = (fileName) => {
        // Toggle selection of the file
        setSelectedFile(selectedFile === fileName ? null : fileName);
        // Reset error and email states
        setError("");
        setEmail("");
    };

    // Validates email format using regex
    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    // Handles the actual download process
    const handleDownload = async () => {
        setIsLoading(true);
        setError("");

        // Validate email before proceeding
        if (!email || !validateEmail(email)) {
            setError(<FormattedMessage id="resources.invalidEmail" />);
            setIsLoading(false);
            return;
        }

        try {
            // Send POST request to download endpoint
            const response = await fetch(`${API_URL}/free-download`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, fileName: selectedFile }),
            });

            if (response.ok) {
                // If successful, create a download link and trigger download
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = selectedFile;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                // Reset states after successful download
                setSelectedFile(null);
                setEmail("");
            } else {
                // Handle server errors
                const errorData = await response.json();
                throw new Error(errorData.error || "Download failed");
            }
        } catch (error) {
            console.error("Download error:", error);
            setError(<FormattedMessage id="resources.downloadError" />);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-[#ede6d6] pb-20 px-8 relative">
            <div className="container mx-auto px-4 py-12">
                <h1 className="pt-10 pb-10 text-center">
                    <FormattedMessage id="resources.title" />
                </h1>
                <p className="text-center pb-20 max-w-2xl mx-auto">
                    <FormattedMessage id="resources.description" />
                </p>
                {/* Grid layout for products, adjusts based on number of products */}
                <div
                    className={`grid ${
                        products.length === 1
                            ? "grid-cols-1 max-w-md"
                            : "grid-cols-1 md:grid-cols-2 max-w-4xl"
                    } gap-8 mx-auto`}
                >
                    {products.map((product) => (
                        <PackageCard
                            key={product.id}
                            pkg={product}
                            onDownloadClick={handleDownloadClick}
                        />
                    ))}
                </div>
            </div>
            {/* Modal for email input and download confirmation */}
            {selectedFile && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg max-w-md w-full">
                        <h3 className="text-xl font-semibold mb-4">
                            {" "}
                            {selectedFile}
                        </h3>
                        <Label htmlFor="email" className="text-black">
                            <FormattedMessage id="resources.emailLabel" />
                        </Label>
                        <Input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={intl.formatMessage({
                                id: "resources.emailPlaceholder",
                            })}
                            className="mt-1 mb-4"
                        />
                        {error && (
                            <p className="text-red-500 mt-2 text-sm">{error}</p>
                        )}
                        <p className="text-sm text-gray-500 mb-4">
                            <FormattedMessage id="resources.downloadDisclaimer" />
                        </p>
                        <p className="italic text-sm text-gray-500 mb-4">
                            <FormattedMessage id="resources.disclaimer" />
                            <a href="/privacy">
                                <FormattedMessage id="footer.privacy" />
                            </a>
                            .
                        </p>

                        <div className="flex justify-between">
                            <Button
                                onClick={() => setSelectedFile(null)}
                                className="bg-gray-300 text-black hover:bg-gray-400"
                            >
                                <FormattedMessage id="resources.cancel" />
                            </Button>
                            <Button
                                onClick={handleDownload}
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <FormattedMessage id="resources.download" />
                                ) : (
                                    <FormattedMessage id="resources.download" />
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

// Subcomponent for rendering individual product cards
function PackageCard({ pkg, onDownloadClick }) {
    return (
        <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col h-full">
            <img
                src={pkg.image}
                alt={pkg.title}
                className="w-full h-128 object-cover mb-6 rounded"
            />
            <h3 className="mb-3">{pkg.title}</h3>
            <p className="mb-6 flex-grow">{pkg.description}</p>
            <div className="mt-auto flex items-center justify-end">
                <Button
                    onClick={() => onDownloadClick(pkg.fileName)}
                    className="btn-primary"
                >
                    <FormattedMessage id="resources.download" />
                </Button>
            </div>
        </div>
    );
}

export default Resources;
