import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

function SuccessPage() {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(30);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        const redirectTimer = setTimeout(() => {
            navigate("/");
        }, 30000);

        return () => {
            clearInterval(timer);
            clearTimeout(redirectTimer);
        };
    }, [navigate]);

    const handleRedirectNow = () => {
        navigate("/");
    };

    return (
        <div className="bg-[#ede6d6] min-h-screen flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
                <h1 className="text-2xl font-bold mb-4">
                    <FormattedMessage id="success.title" />
                </h1>
                <p className="mb-4">
                    <FormattedMessage id="success.message" />
                </p>
                <button onClick={handleRedirectNow} className="btn-primary">
                    <FormattedMessage id="success.redirectNow" />
                </button>
                <p className="text-sm text-gray-600 mt-8">
                    <FormattedMessage
                        id="success.redirect"
                        values={{ countdown }}
                    />
                </p>
            </div>
        </div>
    );
}

export default SuccessPage;
