const frMessages = {
    // Header FR
    "header.title": "LEX Connect.",

    // Navigation FR
    "nav.home": "Accueil",
    "nav.about": "À propos",
    "nav.services": "Services",
    "nav.translation": "Linguistique",
    "nav.legal": "Juridique",
    "nav.shop": "Boutique",
    "nav.resources": "Bonus",
    "nav.contactme": "Contact",

    // Hero section
    "hero.title": "Votre alliée juridique et linguistique",
    "hero.button": "À propos",
    "hero.description":
        "Je suis Alexandra, juriste indépendante et digital nomade avec une expérience internationale. Mon parcours unique me permet de combiner deux domaines d'expertise clés afin de vous offrir des services sur mesure.",
    "hero.description2":
        "Que vous souhaitiez renforcer votre conformité juridique, protéger votre activité ou développer votre présence sur les marchés francophones, je suis là pour vous accompagner.",

    // Footer
    "footer.swiss": "Suisse",
    "footer.location": "Virtuellement partout",
    "footer.copyright": "LEX Connect. Tous droits réservés.",
    "footer.privacy": "Politique de confidentialité",
    "footer.terms": "Conditions générales de vente",
    "footer.legalnotices": "Mentions légales",

    // Success
    "success.title": "Your payment was successful!",
    "success.message":
        "Thanks for your purchase! A email has been sent with the purchased Template. Please check your inbox and spam folder.",
    "success.redirect": "Return to homepage",

    // Download
    "download.button": "Download",
    "download.loading": "Preparing your download...",
    "download.success":
        "Your download has started. If it doesn't begin automatically, please check your downloads folder.",
    "download.error":
        "Sorry, there was an error processing your download. The link may have expired or be invalid.",

    // Cookie consent
    "cookie.consent":
        "This website uses cookies to enhance your experience and provide essential functionality. ",

    // About page
    "about.title": "Bienvenue, je suis Alexandra !",
    "about.subtitle": "Juriste et nomade indépendante.",

    "about.p1":
        "Tout a commencé avec un Master en droit à l’Université de Louvain en Belgique, suivi d’un Master en Management à la Louvain School of Management.",
    "about.p1.a":
        "Après six années enrichissantes en tant que consultante juridique et juriste dans plusieurs entreprises, j’ai ressenti le besoin d’élargir mes horizons. Je suis convaincue que sortir de sa zone de confort enrichit notre vision et notre expertise.",
    "about.p1.b":
        "En 2020, alors que le monde se confinait, je me suis retrouvée, par un concours de circonstances, coincée hors d'Europe. J'ai alors commencé à aider des entreprises à renforcer leur présence sur les marchés francophones, jonglant entre rédaction et traduction. C'est là que j'ai réalisé que le droit et la communication reposent sur un même principe : chaque mot compte!",
    "about.p1.c":
        "Aujourd'hui, je mets à profit ce parcours unique pour offrir une expertise juridique à mes clients suisses et des services linguistiques à une clientèle internationale.",

    "about.t2": "Un esprit nomade et entrepreneurial",
    "about.p2":
        "Mon bureau ? Le monde entier. En tant que digital nomad, j'ai eu la chance de beaucoup voyager et de créer des liens avec des clients aux quatre coins du globe.",
    "about.p2.a":
        "Cette expérience m’a appris une chose essentielle : nous faisons tous face à des défis similaires, qu'ils soient d’ordre personnel ou professionnel.",
    "about.p2.b":
        "En travaillant avec moi, vous gagnez bien plus qu'une simple prestataire de services. Vous bénéficiez d'une partenaire engagée qui comprend vos challenges, car je les vis également au quotidien.",

    "about.t3": "Mon approche",
    "about.p3":
        "Quand vous travaillez avec moi, vous ne vous contentez pas d'une simple prestataire de services. Vous gagnez :",
    "about.p3.a":
        "une partenaire engagée qui comprend vos défis, car je les vis aussi au quotidien.",
    "about.p3.b": "une approche axée sur la simplicité et la transparence",
    "about.p3.c":
        "des solutions personnalisées pour vous permettre de vous concentrer sur votre coeur de métier.",
    "about.p3.d": "Prêt à collaborer ?",

    // Work together section
    "work.title": "Pourquoi travailler ensemble",
    "work.section1": "Vous souhaitez vous concentrer sur votre cœur de métier",
    "work.description1":
        "Laissez-moi gérer les détails juridiques et linguistiques, pour que vous puissiez consacrer votre temps et votre énergie à votre activité.",
    "work.section2": "Vous avez besoin de solutions sur mesure",
    "work.description2":
        "Chaque entreprise est unique ; profitez de services personnalisés qui répondent à vos besoins spécifiques.",
    "work.section3": "Vous recherchez un partenaire de confiance",
    "work.description3":
        "Vous méritez quelqu'un qui vous écoute, vous accompagne et s'engage à vos côtés pour votre succès.",

    // Contact form
    "contact.title": "Contactez-moi",
    "contact.error":
        "Please fill in all required fields and select at least one service.",
    "contact.firstName": "Prénom",
    "contact.lastName": "Nom",
    "contact.email": "Email",
    "contact.errorInvalidEmail": "Veuillez saisir une adresse e-mail valide.",
    "contact.message": "Votre message",
    "contact.service": "Service(s) concerné(s)",
    "contact.legal": "Juridique ",
    "contact.translation": "Linguistique",
    "contact.other": "Autre",
    "contact.submit": "Envoyer",
    "contact.acceptTerms": "En soumettant ce formulaire, j'accepte la ",
    "contact.acceptTerms.link": "Politique de confidentialité.",
    "contact.errorSending":
        "Échec de l'envoi du message. Veuillez réessayer plus tard.",
    "contact.errorAllFields": "Veuillez remplir tous les champs requis.",
    "contact.errorTerms":
        "Vous devez accepter la Déclaration de protection des données pour continuer.",
    "contact.confirmationTitle": "Message envoyé !",
    "contact.confirmationMessage":
        "Merci pour votre message. Nous vous répondrons dans les meilleurs délais.",
    "contact.confirmationClose": "Fermer",

    // Translation page FR
    "translation.title": "Services linguistiques",
    "translation.description":
        "Des solutions en langue française avec des tarifs flexibles pour les clients du monde entier qui souhaitent renforcer leur présence sur les marchés francophones, assurer la cohérence de leur marque et se démarquer de la concurrence.",
    "translation.packages": "Packages",
    "translation.services": "Services",
    "translation.packagesText": "Looking for an All-in-One Solution?",
    "translation.packagesText2":
        "Choose the package that best fits your needs, with or without additional add-ons, and enjoy a streamlined process from start to finish.",
    "translation.contactButton": "Contacter",

    // Translation block FR
    "translation.block1.title": "Traduction",
    "translation.block1.description":
        "Des services de traduction sur mesure, incluant des traductions marketing, de sites web et juridiques, adaptés aux publics francophones tout en préservant l'intention et le ton d'origine.",
    "translation.block2.title": "Copywriting",
    "translation.block2.description":
        "Des textes captivants et persuasifs pour votre site web, vos supports marketing et vos réseaux sociaux, conçus pour séduire votre audience et refléter parfaitement l’identité unique de votre marque.",
    "translation.block3.title": "Révision",
    "translation.block3.description":
        "Perfectionnement de vos textes et contenus pour garantir lisibilité, clarté, cohérence et professionnalisme.",
    "translation.block4.title": "SEO",
    "translation.block4.description":
        "Optimisation de votre contenu grâce à une intégration stratégique des mots-clés, visant à améliorer votre visibilité sur les moteurs de recherche et à attirer un trafic ciblé.",

    // Translation faq FR
    "translation.faq": "FAQ",
    "translation.faq.question1":
        "Pourquoi choisir des services de traduction professionnels ?",
    "translation.faq.answer1":
        "Les outils automatisés ne parviennent pas toujours à saisir la profondeur et la nuance nécessaires pour transmettre correctement votre message. L'IA ne peut pas capturer la voix unique de votre marque aussi bien qu'un traducteur professionnel. De plus, limiter votre contenu à l'anglais peut vous faire passer à côté d'opportunités importantes sur le marché francophone.",
    "translation.faq.question2": "Quelles langues traduisez-vous ?",
    "translation.faq.answer2":
        "Je traduis principalement de l'anglais vers le français, notamment pour les marchés de la Suisse, de la Belgique, de la France et du Luxembourg. Je peux également effectuer des traductions de l'allemand vers le français si nécessaire.",
    "translation.faq.question3":
        "Quels sont vos tarifs pour la traduction et la rédaction ?",
    "translation.faq.answer3":
        "Mes tarifs varient en fonction de la complexité et de l'ampleur du projet, ainsi que de facteurs tels que le nombre de mots, la technicité du texte et le délai de livraison. Pour un devis précis adapté à votre projet, n'hésitez pas à me contacter directement.",
    "translation.faq.question4": "Pouvez-vous gérer des projets urgents ?",
    "translation.faq.answer4":
        "Oui, je peux gérer des projets urgents. Un supplément peut être demandé pour des délais accélérés, et ma disponibilité dépendra de la complexité du projet. Pour assurer un traitement rapide, contactez-moi dès que possible avec les détails de votre demande. Je ferai de mon mieux pour répondre à vos besoins.",

    // Translation contact FR

    "translation.contact":
        "Vous avez des questions ou besoin de plus d'informations ?",
    "translation.contact.2":
        "Renforcez votre présence sur le marché francophone, engagez plus efficacement vos clients, assurez la cohérence de votre marque et démarquez-vous de la concurrence !",
    "translation.contact.button": "Contact",

    // Legal page // Legal page FR // Legal page // Legal page // Legal page FR // Legal page // Legal page // Legal page FR // Legal page
    "legal.title": "Services juridiques",
    "legal.description":
        "Des solutions juridiques sur mesure pour les entrepreneurs et indépendants en Suisse, vous garantissant conformité légale et tranquillité d'esprit.",
    "legal.contactButton": "Contactez-moi",

    // Legal block FR
    "legal.block1.title": "Révision et mise à jour de documents",
    "legal.block1.description":
        "Assurez la clarté, l’exactitude et la conformité de vos documents grâce à une révision sur mesure, adaptée à vos besoins et à votre activité.",
    "legal.block2.title": "Rédaction de documents sur mesure",
    "legal.block2.description":
        "Des contrats et autres documents juridiques conçus pour répondre à vos besoins, protéger vos intérêts et garantir des termes clairs.",
    "legal.block3.title": "Modèles personnalisables",
    "legal.block3.description":
        "Découvrez dans la boutique une gamme de {link} conçus pour répondre aux divers besoins et profils d'entrepreneurs. Adaptez-les facilement à votre situation pour garantir des documents conformes, gagner du temps et simplifier vos démarches.",
    "legal.block3.linkText": "modèles juridiques",
    "legal.block4.title": "Services sur demande",
    "legal.block4.description":
        "Si vous avez des besoins spécifiques qui ne sont pas couverts par les services ci-dessus, n’hésitez pas à me contacter pour discuter de solutions qui répondent à vos exigences.",

    // Legal faq
    "legal.faq": "FAQ",
    "legal.faq.question1": "Travaillez-vous avec les petites entreprises ?",
    "legal.faq.answer1":
        "Oui, je travaille volontiers avec les petites entreprises. En tant qu'entrepreneuse moi-même, je comprends vos besoins spécifiques et je propose un soutien personnalisé qui correspond à votre vision et à vos objectifs.",
    "legal.faq.question2":
        "Devrais-je plutôt envisager de faire appel à un avocat ?",
    "legal.faq.answer2":
        "En tant que juriste indépendante non inscrite au barreau, je mets mon expertise juridique à votre service. Toutefois, je ne suis pas autorisée à représenter des clients devant les tribunaux ni à fournir des services réservés aux avocats. Mes services ne remplacent en aucun cas les conseils juridiques d'un avocat lorsque ceux-ci sont nécessaires ou recommandés.",
    "legal.faq.question3":
        "Quels sont vos tarifs pour les services juridiques ?",
    "legal.faq.answer3":
        "Les tarifs varient en fonction du service et de sa complexité. Je propose des forfaits fixes pour certains services et des tarifs horaires pour d'autres. Contactez-moi pour discuter de vos besoins et obtenir un devis personnalisé.",
    "legal.faq.question4":
        "Pourquoi certains outils en ligne sont-ils moins chers voire gratuits ?",
    "legal.faq.answer4":
        "Les ressources gratuites ou peu coûteuses, comme les modèles génériques ou les outils d'IA, peuvent sembler attrayantes, mais elles manquent souvent de personnalisation et de précision. Mes services, en revanche, offrent des solutions personnalisées, adaptées précisément à vos besoins.",

    // Legal contact
    "legal.contact":
        "Protégez-vous, renforcez votre crédibilité, ayez l'esprit tranquille",
    "legal.contact.2":
        "En tant que juriste indépendante non inscrite au barreau, je mets mon expertise juridique à votre service. Toutefois, je ne suis pas habilitée à représenter des clients devant les tribunaux ni à fournir des services réservés aux avocats. Mes services ne remplacent en aucun cas les conseils et services d'un avocat lorsque ceux-ci sont nécessaires ou recommandés.",
    "legal.contact.button": "Contactez-moi",

    // shop page
    "shop.title": "Boutique des templates",
    "shop.description":
        "Découvrez ci-dessous des modèles de documents juridiques téléchargeables et personnalisables, spécialement conçus pour les entrepreneurs et freelances en Suisse. Gagnez du temps et simplifiez la création de vos documents essentiels avec ces outils pratiques.",
    "shop.description.a": " ",
    "shop.purchase.button": "Bientôt disponible",
    "shop.disclaimer":
        "En tant que juriste indépendante non inscrite au barreau, je mets mon expertise juridique à votre service. Toutefois, je ne suis pas autorisée à représenter des clients devant les tribunaux ni à fournir des services réservés aux avocats. Mes services ne remplacent en aucun cas les conseils juridiques d'un avocat lorsque ceux-ci sont nécessaires ou recommandés.",

    "shop.title.1": "Contrat de prestation de services",
    "shop.description.1": "Modèle standard avec les éléments clés",
    "shop.title.2": "Conditions générales de vente (CGV)",
    "shop.description.2":
        "Destinées aux freelances et profils créatifs vendant des produits digitaux en ligne",

    // Resources page
    "resources.title": "Ressource gratuite",
    "resources.description":
        "Cette page propose un guide gratuit et des outils pour aider les entrepreneurs et les indépendants en Suisse à s'y retrouver dans le paysage juridique.",
    "resources.title.1":
        "Guide pour freelances et indépendants avec un site web en Suisse",
    "resources.description.1":
        "Découvrez les documents clés et des recommandations pour vous conformer aux obligations légales, protéger votre activité et renforcer votre crédibilité auprès de vos clients.",
    "resources.downloadError":
        "Une erreur s'est produite lors du téléchargement",
    "resources.emailLabel": "Email",
    "resources.download": "Télécharger",
    "resources.emailPlaceholder": "Saisissez votre email pour télécharger",
    "resources.downloadDisclaimer":
        "*Le téléchargement commencera automatiquement",
    "resources.disclaimer":
        "En soumettant votre adresse e-mail, vous consentez à son utilisation pour un suivi interne. Nous ne partagerons pas vos informations avec des tiers. Pour plus de détails, consultez notre ",
    "resources.cancel": "Annuler",
    "resources.invalidEmail": "Veuillez saisir une adresse e-mail valide.",
    "resources.downloadPopupTitle": "Ressource demandée",
    "resources.downloadPopupDescription":
        "Le téléchargement va démarrer automatiquement. Si ce n'est pas le cas, cliquez sur le bouton ci-dessous.",

    // Privacy page // Privacy page // Privacy page // Privacy page // Privacy page // Privacy page
    "privacy.title": "Déclaration de protection des données",

    "privacy.h1": "I. Remarques préliminaires",
    "privacy.t1.a":
        "La protection de vos données personnelles est notre priorité. Nous ne collectons que les informations strictement nécessaires à l'utilisation de notre site internet ",
    "privacy.t1.b": "www.lexconnect.ch ",
    "privacy.t1.c":
        "(le « Site ») et à la fourniture de nos produits et de nos services. Toutes les données collectées sont traitées avec le plus grand soin.",
    "privacy.t1.d":
        "Dans la mesure où nous collectons et traitons des données personnelles, nous respectons les exigences de la Loi fédérale suisse sur la protection des données (« LPD ») ainsi que du Règlement général sur la protection des données de l'UE (« RGPD ») lorsque celui-ci est applicable.",
    "privacy.t1.e":
        "La présente déclaration de protection des données (la « Déclaration ») décrit nos pratiques en matière de collecte, stockage, utilisation et divulgation des données personnelles via notre Site.",
    "privacy.t1.f":
        "Nous vous invitons à lire attentivement cette Déclaration. Si vous n'en acceptez pas les termes, nous vous prions de ne pas utiliser le Site ni nos services.",

    "privacy.h2": "II. Portée de la Déclaration",
    "privacy.t2.a":
        "La Déclaration s'applique à l'utilisation du Site, exploité par Alexandra Dallüge en tant qu'indépendante, ainsi qu'à toutes les données personnelles collectées via le Site.",
    "privacy.t2.b":
        "Veuillez noter que notre Site peut contenir des liens vers des sites web tiers, qui sont soumis à leurs propres politiques de confidentialité. Nous déclinons toute responsabilité concernant la manière dont ces tiers collectent, utilisent ou protègent vos données personnelles. Nous vous recommandons de consulter les politiques de confidentialité de ces sites tiers avant de leur fournir vos informations personnelles.",

    "privacy.h3": "III. Types de données collectées",
    "privacy.t3.a":
        "Lors de votre visite du Site, notre serveur web enregistre automatiquement certaines informations relatives à votre visite, telles que votre adresse IP, le site web de provenance, le type de navigateur utilisé, ainsi que les pages consultées, y compris la date et la durée de votre visite.",
    "privacy.t3.b":
        "Nous collectons également les données personnelles que vous nous fournissez directement via notre Site, notamment lorsque vous remplissez un formulaire de contact, téléchargez une ressource, prenez rendez-vous ou effectuez un achat en ligne. Les types de données collectées incluent :",
    "privacy.t3.p1": "Données d’identité telles que vos prénoms, noms.",
    "privacy.t3.p2":
        "Données de contact telles que les adresses (adresse de facturation, adresse e-mail) et le numéro de téléphone.",
    "privacy.t3.p3":
        "Données financières telles que les coordonnées bancaires, les informations de carte de paiement et les informations sur les paiements effectués.",
    "privacy.t3.p4": "Données relatives aux prestations reçues ou fournies.",
    "privacy.t3.p5":
        "Informations professionnelles, en rapport avec vos fonctions et activités professionnelles.",
    "privacy.t3.p6":
        "Toute autre information communiquée lors de nos échanges.",
    "privacy.t3.c":
        "Nous nous engageons à traiter ces informations de manière confidentielle et uniquement dans le but pour lequel elles ont été collectées.",
    "privacy.t3.d":
        "Nous ne traitons pas les données sensibles telles que définies à l’article 5, c de la LPD.",

    "privacy.h4": "IV. Finalité des données",
    "privacy.t4.a":
        "Lorsque vous utilisez nos services, naviguez sur le Site ou nous contactez, nous collectons et traitons vos données personnelles principalement pour les finalités suivantes :",
    "privacy.t4.p1":
        "Communication : pour communiquer avec vous et établir votre identité.",
    "privacy.t4.p2":
        "Prestation de nos services :  pour préparer, conclure et documenter les contrats nécessaires à la fourniture de nos services, ainsi que pour la facturation.",
    "privacy.t4.p3":
        "Traitement des commandes : pour gérer et traiter les commandes effectuées sur le Site.",
    "privacy.t4.p4":
        "Distribution de ressources gratuites : lorsque vous téléchargez des ressources gratuites depuis notre Site, nous collectons votre adresse e-mail pour mesurer l'intérêt porté à nos offres.",
    "privacy.t4.p5":
        "Poursuite de nos intérêts légitimes : pour optimiser nos services et prévenir les activités frauduleuses.",
    "privacy.t4.p6":
        "Conformité légale : pour respecter les obligations légales, telles que celles liées à la comptabilité, à la fiscalité ou à la protection des données.",
    "privacy.t4.b":
        "La base juridique du traitement de vos données repose principalement sur votre consentement, l’exécution d’un contrat, notre intérêt légitime et l’obligation légale, conformément à l'article 6, alinéa 1 du RGPD.",

    "privacy.h5": "V. Transfert des données à des tiers",
    "privacy.t5.a":
        "Nous protégeons vos données et nous ne les vendons pas à des tiers. Cependant, pour vous offrir nos produits et services dans les meilleures conditions, il peut être nécessaire de partager certaines informations avec des partenaires ou prestataires externes.",
    "privacy.t5.b":
        "Nous pouvons être amenés à partager vos données avec les entités suivantes :",
    "privacy.t5.p1":
        "Prestataire informatique : un prestataire indépendant qui assure la maintenance de nos serveurs peut avoir un accès limité à vos données lors d’interventions techniques. Nous veillons à ce que ce prestataire respecte des normes strictes de confidentialité et de sécurité des données.",
    "privacy.t5.p2": "Prestataires externes :",
    "privacy.t5.p2.1":
        "Calendly : pour la gestion des rendez-vous en ligne. Vous pouvez consulter leur politique de confidentialité ici..",
    "privacy.t5.p2.2":
        "Stripe : pour le traitement des paiements en ligne. Vous pouvez consulter leur politique de confidentialité ici.",
    "privacy.t5.p3":
        "Autorités légales : nous pouvons être amenés à transmettre vos données aux autorités compétentes pour répondre à des obligations légales ou réglementaires, ou pour protéger nos droits.",
    "privacy.t5.c":
        "Si nous devons transférer vos données vers un pays en dehors de la Suisse ou de l’UE/EEE qui ne dispose pas d'une protection des données adéquate, nous nous assurons que des garanties appropriées sont mises en place pour protéger vos données personnelles.",

    "privacy.h6": "VI. Réseaux sociaux",
    "privacy.t6.a":
        "Nous exploitons des pages et des profils sur des réseaux sociaux et d’autres plateformes tierces.",
    "privacy.t6.b":
        "Lorsque vous interagissez avec nous via ces plateformes, par exemple en commentant nos publications ou en aimant nos contenus, nous collectons des données que vous nous fournissez directement. Nous recevons également des données fournies par les plateformes elles-mêmes, telles que des statistiques d’utilisation.",
    "privacy.t6.c":
        "Les fournisseurs de ces plateformes peuvent analyser votre utilisation et combiner ces données avec d’autres informations dont ils disposent à votre sujet, notamment pour des fins de marketing, d’études de marché et de gestion de leurs plateformes. Ils sont responsables du traitement de vos données conformément à leurs politiques de confidentialité.",
    "privacy.t6.d": "Nous utilisons actuellement les plateformes suivantes.",
    "privacy.t6.p1": "LinkedIn: Politique de confidentialité",
    "privacy.t6.p2": "Instagram: Politique de confidentialité",
    "privacy.t6.e":
        "Lorsque vous cliquez sur un lien vers l’un de nos profils sur les réseaux sociaux, une connexion directe est établie entre votre navigateur et le serveur du réseau social concerné. Ce réseau social reçoit alors l'information que vous avez visité notre Site, ainsi que votre adresse IP. Si vous êtes connecté à votre compte sur ce réseau social au moment de votre visite, il pourra associer directement votre visite de notre Site à votre profil.",
    "privacy.t6.f":
        "Veuillez consulter les politiques de confidentialité de ces réseaux sociaux pour comprendre comment vos données sont utilisées par ces plateformes. Notez que nous n’avons aucun contrôle sur ces pratiques et déclinons toute responsabilité à cet égard.",

    "privacy.h7": "VII. Sécurité des données",
    "privacy.t7.a":
        "Notre serveur est hébergé en Allemagne auprès d'un hébergeur reconnu pour sa fiabilité et sa sécurité. Ce dernier assure uniquement l'hébergement du serveur. Notre serveur a été conçu spécifiquement pour répondre à nos besoins par un partenaire technique indépendant de confiance. Ce partenaire est responsable du développement, de la gestion technique et de la maintenance de notre Site, sous notre supervision directe. La protection de vos données personnelles est assurée conjointement par nous et ce partenaire, dans le strict respect de notre Déclaration. Nous maintenons ainsi un contrôle rigoureux sur le serveur, le Site et les données.",
    "privacy.t7.b":
        "Nous avons mis en place des mesures de sécurité appropriées pour protéger vos données personnelles contre la perte, l’accès non autorisé, l’utilisation abusive, la divulgation, la destruction ou la modification.",
    "privacy.t7.c":
        "Cependant, bien que nous appliquions les meilleures pratiques de l'industrie pour garantir la sécurité de vos données, il est important de reconnaître que la transmission d'informations via Internet comporte des risques inhérents. En conséquence, nous ne pouvons pas garantir une sécurité absolue des informations transmises en ligne.",

    "privacy.h8": "VIII. Délais de conservation",
    "privacy.t8.a":
        "Nous conservons vos données personnelles pendant la durée nécessaire pour atteindre les finalités pour lesquelles elles ont été collectées, et en tout état de cause, pour une période maximale de 10 ans, conformément aux obligations comptables et fiscales suisses.",
    "privacy.t8.b":
        "Lorsque vos données personnelles ne sont plus nécessaires pour atteindre ces finalités, nous les supprimerons ou les anonymiserons, sauf si des obligations légales ou réglementaires exigent leur conservation pour une période plus longue.",

    "privacy.h9": "IX. Vos droits",
    "privacy.t9.a":
        "En vertu des lois sur la protection des données applicables, vous disposez des droits suivants concernant le traitement de vos données personnelles :",
    "privacy.t9.p1":
        "Droit d'accès : vous pouvez demander gratuitement des informations sur les données personnelles que nous traitons à votre sujet.",
    "privacy.t9.p2":
        "Droit de rectification : vous pouvez consulter et demander la correction des données personnelles inexactes ou incomplètes que nous détenons.",
    "privacy.t9.p3":
        "Droit à l'effacement : vous pouvez demander la suppression de vos données personnelles dans certaines circonstances, notamment si les données ne sont plus nécessaires aux fins pour lesquelles elles ont été collectées ou si vous retirez votre consentement.",
    "privacy.t9.p4":
        "Droit d'opposition : vous pouvez vous opposer au traitement de vos données personnelles, notamment à des fins de marketing direct, y compris l’e-mail marketing.",
    "privacy.t9.p5":
        "Droit à la portabilité : vous pouvez demander la transmission de vos données personnelles dans un format numérique courant et lisible, ou leur transfert à un autre responsable du traitement.",
    "privacy.t9.p6":
        "Droit à la limitation: vous pouvez demander la restriction du traitement de vos données personnelles dans certaines situations, par exemple, lorsque vous contestez l'exactitude des données.",
    "privacy.t9.p7":
        "Droit de révocation : vous pouvez retirer à tout moment votre consentement au traitement de vos données. Cette révocation n’affecte pas la légalité du traitement effectué avant la révocation.",
    "privacy.t9.p8":
        "Droit de recours : vous avez le droit de déposer une plainte auprès d'une autorité de contrôle compétente si vous estimez que le traitement de vos données personnelles n'est pas conforme aux lois en vigueur.",
    "privacy.t9.b":
        "Pour exercer ces droits, veuillez nous contacter par e-mail à l’adresse suivante : EMAIL HERE. Nous nous engageons à répondre à votre demande dans un délai raisonnable et conformément aux exigences légales.",

    "privacy.h10": "X. Cookies",
    "privacy.t10.a":
        "Notre Site peut utiliser des cookies et d’autres technologies similaires, comme les balises web et les pixels, pour améliorer l'expérience utilisateur, faciliter certaines fonctionnalités et pour des raisons de sécurité. Les cookies sont de petits fichiers de données stockés sur votre appareil lors de la navigation sur notre Site.",
    "privacy.t10.b": "Les cookies que nous utilisons sont classés comme suit :",
    "privacy.t10.p1":
        "Cookies strictement nécessaires : ces cookies sont essentiels au fonctionnement de base du Site. Ils permettent, par exemple, la navigation sur le Site et la soumission de formulaires. Ces cookies ne collectent aucune information personnelle identifiable et ne nécessitent pas votre consentement, car ils sont indispensables pour assurer le bon fonctionnement du Site.",
    "privacy.t10.p2":
        "Cookies fonctionnels : ces cookies mémorisent vos préférences pour améliorer votre expérience utilisateur en adaptant le Site à vos besoins. Par exemple, en se souvenant de la langue que vous avez choisie.",
    "privacy.t10.p3":
        "Cookies de tiers : le formulaire de prise de rendez-vous via Calendly et notre solution de paiement Stripe peuvent placer des cookies sur votre appareil. Le consentement est requis pour ces cookies. Vous avez la possibilité de les accepter ou les refuser via les paramètres de cookies de notre Site ou en modifiant les paramètres de votre navigateur.",
    "privacy.t10.c":
        "Nous n'utilisons pas de cookies pour la publicité ciblée ou l'analyse approfondie du comportement des utilisateurs.",
    "privacy.t10.d":
        "Nous nous efforçons de garantir que l'ensemble des fonctionnalités de notre Site soit accessible même si vous choisissez de refuser les cookies non essentiels. Cependant, certaines fonctionnalités peuvent ne pas fonctionner de manière optimale si vous ne les acceptez pas.",

    "privacy.h11": "XI. Responsable et contact",
    "privacy.t11.a": "Alexandra Dallüge",
    "privacy.t11.b": "13, Chemin de la Moraine, 1008 Prilly",
    "privacy.t11.c": "Email: EMAIL HERE",
    "privacy.t11.d":
        "Pour toute question relative à la protection des données ou pour exercer vos droits conformément à la Déclaration, veuillez nous contacter à l'adresse e-mail suivante EMAIL HERE",

    "privacy.h12": "XII. Modification de la Déclaration",
    "privacy.t12.a":
        "La présente Déclaration n’établit pas de lien contractuel. Nous nous réservons le droit de la modifier à tout moment. Nous vous encourageons à consulter régulièrement cette page pour vous informer des éventuelles modifications. La version en vigueur est celle publiée sur notre Site et remplace toutes les versions antérieures.",

    "privacy.h13": "XIII. Clause de non-responsabilité",
    "privacy.t13.a": "Liens externes",
    "privacy.t13.b":
        "Notre Site peut contenir des liens vers des sites web tiers. Nous n'exerçons aucun contrôle sur ces sites et déclinons toute responsabilité concernant leur contenu, leurs pratiques de confidentialité ou leurs politiques. L'inclusion de liens vers ces sites n'implique pas notre approbation ou validation de leur contenu.",
    "privacy.t13.c": "Droits d’auteur",
    "privacy.t13.d":
        "Le contenu du Site est protégé par les droits d'auteur. Toute copie, reproduction, distribution ou transmission de tout ou partie du contenu produit par le Site est interdite sans notre autorisation écrite préalable.",
    "privacy.t13.e": "Exactitude des informations",
    "privacy.t13.f":
        "Nous nous efforçons de maintenir l'exactitude des informations sur ce Site, mais nous ne garantissons pas leur exhaustivité. Nous déclinons toute responsabilité pour les dommages résultant de l'utilisation ou de la non-utilisation des informations fournies.",

    "privacy.h14": "XIV. Droit applicable et litiges",
    "privacy.t14.a":
        "Cette Déclaration, ainsi que l'utilisation du Site, est régie par le droit suisse. Tout litige en découlant sera soumis à la compétence exclusive des tribunaux suisses. ",
    "privacy.t14.b":
        "Ces dispositions s'appliquent sous réserve des règles légales impératives en vigueur dans le pays de résidence habituelle du Client. En cas de conflit entre les dispositions de cette politique de confidentialité et les règles légales impératives du pays de résidence habituelle du Client, ces dernières prévaudront.",

    "privacy.h15": "XV. Langues",
    "privacy.t15.a":
        "Le Site est disponible en français et anglais. En cas de divergence ou d'ambiguïté entre les différentes versions linguistiques du contenu du Site ou ou de la Déclaration, la version française prévaut et fait foi.",

    "privacy.lastUpdated": "Dernière date de mise à jour : 30/09/2024.",

    // Terms page
    "terms.title": "Conditions générales de vente",
    "terms.h1": "Article 1. Préambule",
    "terms.t1.a":
        "Les présentes conditions générales de vente (ci-après les « CGV ») régissent toutes les ventes et les commandes passées via le site ",
    "terms.t1.b": "www.lexconnect.ch ",
    "terms.t1.c":
        "(ci-après le « Site » ou la « Boutique en ligne ») exploité par:",
    "terms.t1.d": "Alexandra Dallüge (ci-après « Alexandra » ou « Nous »)",
    "terms.t1.e": "Chemin de la Moraine, 13",
    "terms.t1.f": "1008 Prilly, Suisse",
    "terms.t1.g": "Email: EMAIL HERE",
    "terms.t1.h":
        "Alexandra est une juriste indépendante non inscrite au barreau. Il est important de noter qu’Alexandra ne peut pas représenter les Clients devant les tribunaux et ne fournit pas de services réservés aux avocats. Ses services ne remplacent en aucun cas les conseils et services d'un avocat lorsque ceux-ci sont nécessaires ou recommandés.",
    "terms.t1.i":
        "Les accords spécifiques conclus avec le client (le « Client ») prévalent sur les présentes CGV. Les conditions du Client qui diffèrent de ou sont en contradiction avec ces CGV ne seront pas reconnues.",
    "terms.t1.j":
        "Alexandra se réserve le droit de modifier les présentes CGV à tout moment, à sa seule discrétion. Les CGV applicables sont celles en vigueur sur le Site au moment de la commande.",
    "terms.t1.k":
        "En accédant aux services et produits proposés sur le Site, le Client reconnaît avoir pris connaissance des CGV, les avoir lues et comprises. Une confirmation de lecture et d’approbation des CGV est requise avant la validation de la commande.",

    "terms.h2": "Article 2. Produits digitaux",
    "terms.t2.a":
        "La Boutique en ligne peut proposer divers produits digitaux, comprenant à la fois des produits gratuits et des produits payants (ci-après, les « Produits Digitaux » ou le « Produit Digital »).",
    "terms.t2.b":
        "Les Produits Digitaux payants se composent principalement de modèles de documents juridiques destinés aux entrepreneurs et indépendants en Suisse. Ces modèles sont fournis en format Word et peuvent être modifiés et personnalisés selon les besoins spécifiques du Client.",
    "terms.t2.c":
        "Les Produits Digitaux disponibles sur la Boutique en ligne peuvent être sujets à modifications. Alexandra se réserve le droit de modifier à tout moment et sans préavis les Produits Digitaux disponibles sur le Site ainsi que les contenus associés tels que les prix et les descriptions.",

    "terms.h3": "Article 3. Commande",
    "terms.t3.a":
        "Avant de passer une commande, le Client doit vérifier que le Produit Digital sélectionné correspond bien à ses besoins. La commande constitue un engagement contractuel ferme et définitif (ci-après, la « Commande »).",
    "terms.t3.b": "Pour passer une Commande, le Client doit :",
    "terms.t3.p1":
        "Sélectionner le Produit Digital souhaité et confirmer son choix,",
    "terms.t3.p2":
        "Être redirigé vers la page de paiement où il devra lire et accepter les CGV et effectuer le paiement.",
    "terms.t3.c":
        "Une fois le paiement effectué, le Produit Digital sera disponible pour le téléchargement via un lien sécurisé. Ce lien sera envoyé au Client par e-mail à l'adresse fournie ; il sera valable pendant 24 heures et ne pourra être utilisé qu'une seule fois. Alexandra peut, à sa seule discrétion, fournir un nouveau lien de téléchargement si le Client en fait la demande.",
    "terms.t3.d":
        "Le Client s'engage à fournir des coordonnées valides lors de la Commande. Alexandra ne pourra être tenue responsable des difficultés à exécuter la Commande résultant d'informations incorrectes fournies par le Client.",
    "terms.t3.e":
        "En outre, le Client est responsable de s'assurer que son matériel et ses logiciels sont compatibles avec le Produit Digital pour garantir son bon fonctionnement. Nous ne garantissons pas la compatibilité des Produits Digitaux avec les systèmes ou logiciels spécifiques du Client.",

    "terms.h4": "Article 4. Prix",
    "terms.t4.a":
        "Le prix applicable est celui indiqué dans la Boutique en ligne au moment de la Commande. Alexandra se réserve le droit de modifier les prix des Produits digitaux à tout moment.",
    "terms.t4.b":
        "Tous les prix sont indiqués en CHF (francs suisses). Le prix total de votre Commande, incluant tous les éventuels frais et taxes applicables, sera confirmé à la fin du processus de commande sur le Site.",

    "terms.h5": "Article 5. Modalités de paiement",
    "terms.t5.a":
        "Le paiement doit être effectué au moment de la Commande, par l’un des moyens de paiement disponibles sur le Site, y compris, mais sans s’y limiter, via Stripe.",
    "terms.t5.b":
        "En procédant au paiement, le Client autorise le traitement des transactions par Stripe, qui est un prestataire de services de paiement sécurisé.",
    "terms.t5.c":
        "Alexandra décline toute responsabilité pour les frais ou montants supplémentaires facturés par Stripe, l’émetteur de la carte de crédit du Client, ou sa banque.",
    "terms.t5.d":
        "En cas de refus de paiement, Alexandra se réserve le droit d’annuler la Commande et de suspendre la livraison des Produits Digitaux.",

    "terms.h6": "Article 6. Garantie",
    "terms.t6.a":
        "Nous nous engageons à faire en sorte que les Produits Digitaux fournis soient conformes à leur description et fonctionnent comme prévu au moment de l'achat.",
    "terms.t6.b":
        "Après réception du Produit Digital, le Client doit vérifier immédiatement sa conformité et l'absence de défauts. Toute réclamation concernant des défauts ou des dommages doit être signalée à Alexandra par e-mail à l'EMAIL HERE dans un délai de 7 jours ouvrables à compter de la réception du Produit Digital. Passé ce délai, le Produit Digital sera considéré comme accepté, sauf preuve de défauts cachés.",
    "terms.t6.c":
        "En cas de défaut ou de dommage, une nouvelle version fonctionnelle du Produit Digital sera fournie sans frais supplémentaires, à condition que le défaut ne résulte pas d'une modification apportée par le Client. Le Client recevra la version corrigée par e-mail. Les réclamations concernant des aspects subjectifs du contenu ne seront pas prises en compte.",

    "terms.h7": "Article 7. Responsabilité",
    "terms.t7.a":
        "Notre responsabilité totale en vertu de ces CGV est limitée au montant payé pour le Produit Digital concerné. Nous ne sommes pas responsables des dommages indirects, spéciaux ou consécutifs résultant de l'utilisation de nos Produits Digitaux, y compris, mais sans s'y limiter, la perte de profit, l'interruption d'activité ou la perte de données.",
    "terms.t7.b":
        "En outre, nous ne serons pas tenus responsables de tout manquement à nos obligations en vertu des présentes CGV si ce manquement est causé par des événements de force majeure tels que définis par la législation en vigueur, incluant mais sans s’y limiter à des catastrophes naturelles, des guerres ou des pannes de réseau.",
    "terms.t7.c":
        "Les Produits Digitaux sont fournis “tels quels” et ne remplacent en aucun cas les conseils d’un avocat spécialisé. Alexandra ne peut être tenue responsable de l'utilisation faite de ces Produits Digitaux ni des conséquences, directes ou indirectes, qui en découlent.",
    "terms.t7.d":
        "La présente limitation de responsabilité ne s'applique pas en cas de négligence grave ou de faute intentionnelle de notre part, ni pour les dommages corporels ou tout autre cas où la loi suisse interdit une telle limitation.",

    "terms.h8": "Article 8. Droit de rétractation et de remboursement",
    "terms.t8.a":
        "Les Produits Digitaux, en raison de leur nature immatérielle et téléchargeable, ne sont pas soumis au droit de rétractation. Par conséquent, une fois le paiement effectué et le Produit Digital mis à disposition, le Client ne dispose d’aucun droit de rétractation, de révocation, de retour ou de remboursement.",

    "terms.h9": "Article 9.  Protection des données",
    "terms.t9.a":
        "La protection des données personnelles du Client est notre priorité. La déclaration de protection des données, disponible sur le Site, fait partie intégrante des présentes CGV.",
    "terms.t9.b":
        "En acceptant ces CGV, le Client confirme avoir lu et compris la déclaration de protection des données et consent à la collecte et au traitement de ses données personnelles conformément à celle-ci.",

    "terms.h10": "Article 10. Propriété intellectuelle et droits d’utilisation",
    "terms.t10.a":
        "Les Produits Digitaux et le Site sont protégés par le droit d'auteur en Suisse. Tous les droits non expressément accordés au Client sont réservés à Alexandra.",
    "terms.t10.b":
        "Toute reproduction, modification, diffusion ou autre utilisation, totale ou partielle, du contenu publié sur le Site est interdite sans l'accord préalable et écrit d'Alexandra.",
    "terms.t10.c":
        "Le Client acquiert un droit d'utilisation non exclusif, non cessible et intransmissible des Produits Digitaux disponibles sur la Boutique en ligne, moyennant paiement. Ce droit est réservé exclusivement à l'usage personnel ou professionnel du Client. Le Client peut modifier et adapter les Produits Digitaux payants pour ses besoins personnels ou professionnels, mais ne peut ni les revendre, ni les redistribuer, ni les utiliser à des fins commerciales autres que celles prévues dans leur usage normal.",
    "terms.t10.d":
        "Les Produits Digitaux mis à disposition gratuitement sur le Site sont également réservés à un usage personnel uniquement. Le Client accepte que ces ressources ne puissent être copiées, modifiées, distribuées ou partagées de toute autre manière que celle prévue pour un usage personnel. Alexandra se réserve le droit de modifier ou de retirer ces ressources gratuites à tout moment et sans préavis.",

    "terms.h11": "Article 11. Clause de sauvegarde",
    "terms.t11.a":
        "Si l'une des clauses des présentes CGV devait être déclarée nulle, invalide ou inapplicable en vertu du droit applicable, cette invalidité n'affectera pas la validité des autres clauses, qui demeureront pleinement en vigueur.",
    "terms.t11.b":
        "Dans ce cas, Alexandra convient de substituer à la clause invalide une clause valide qui reflète autant que possible l'intention initiale.",

    "terms.h12": "Article 12. Droit applicable et résolution des litiges",
    "terms.t12.a":
        "Les présentes CGV ainsi que toutes les relations juridiques en découlant sont régies par le droit suisse.",
    "terms.t12.b":
        "En cas de litige, les parties s'engagent à tenter de résoudre le différend à l'amiable avant de saisir les tribunaux. Si une résolution amiable n'est pas possible, les tribunaux suisses seront compétents pour trancher le litige.",
    "terms.t12.c":
        "Ces dispositions s'appliquent sous réserve des dispositions légales impératives en vigueur dans le pays de résidence habituelle du Client. En cas de conflit entre les dispositions des présentes CGV et les dispositions légales impératives du pays de résidence habituelle du Client, ces dernières prévaudront.",

    "terms.h13": "Article 13. Langues",
    "terms.t13.a":
        "Le Site est disponible en français et anglais. En cas de divergence ou d'ambiguïté entre les différentes versions linguistiques du contenu du Site ou des CGV, la version française prévaut et fait foi.",

    "terms.lastUpdated": "Dernière mise à jour: 29/09/2024",

    // Legal Notices page // Legal Notices page
    "legalnotice.title": "Mentions légales",
    "legalnotice.h1": "Nom du site",
    "legalnotice.t1.a": "www.lexconnect.ch (le “Site”)",
    "legalnotice.h2": "Propriétaire et responsable de la publication",
    "legalnotice.t2.a": "Alexandra Dallüge",
    "legalnotice.t2.b": "13, Chemin de la Moraine, CH-1008 Prilly, Switzerland",
    "legalnotice.t2.c": "Contact: EMAIL HERE",
    "legalnotice.t2.d": "Numéro IDE: CHE 349.209.011",

    "legalnotice.h3": "Hébergeur cloud",
    "legalnotice.t3.a": "Hetzner Online GmbH",
    "legalnotice.t3.b": "Industriestr. 25, 91710 Gunzenhausen, Germany",
    "legalnotice.t3.c": "Tel.: +49 (0)9831 505-0",
    "legalnotice.t3.d": "Email: EMAIL HERE",

    "legalnotice.h4": "Statut professionnel",
    "legalnotice.t4.a":
        "Alexandra Dallüge, propriétaire du site et prestataire des services proposés, est juriste indépendante non inscrite au barreau. En tant que telle, elle met son expertise juridique à votre service. Toutefois, elle ne peut pas représenter des clients devant les tribunaux et ne fournit pas de services réservés aux avocats. Les recommandations et services juridiques fournis visent à accompagner les clients dans leurs besoins, mais ne remplacent pas les conseils d'un avocat.",

    "legalnotice.h5": "Propriété intellectuelle",
    "legalnotice.t5.a":
        "Le contenu du Site, y compris les textes, images, logos et autres éléments, est protégé par les droits d'auteur. Toute copie, reproduction, distribution ou transmission de tout ou partie du contenu produit sur le Site est interdite sans notre autorisation écrite préalable.",

    "legalnotice.h6": "Responsabilité",
    "legalnotice.t6.a":
        "Vous vous engagez à fournir des informations exactes et à jour lors de l'utilisation de nos services.",
    "legalnotice.t6.b":
        "Nous ne sommes pas responsables des contenus externes accessibles par des liens hypertextes sur ce Site. Bien que nous mettions tout en œuvre pour assurer la précision et la sécurité du Site, nous ne garantissons pas qu'il soit exempt d'erreurs ou de virus.",
    "legalnotice.t6.c":
        "L'utilisateur du Site accepte que l'utilisation se fasse à ses propres risques. Nous ne pouvons être tenus responsables des dommages directs ou indirects résultant de l'utilisation du Site.",
    "legalnotice.t6.d":
        "Notre contenu peut être modifié à tout moment sans préavis. Nous nous réservons le droit de mettre à jour ou de supprimer des informations à tout moment. Il est de la responsabilité de l'utilisateur de consulter régulièrement les mentions légales pour se tenir informé des modifications.",

    "legalnotice.h7": "Protection des données",
    "legalnotice.t7.a":
        "Pour des informations détaillées sur la manière dont nous collectons, utilisons, stockons et protégeons vos données personnelles, ainsi que sur vos droits en matière de protection des données, veuillez consulter notre Politique de confidentialité. Cette déclaration de protection des données inclut également des informations sur notre utilisation des cookies.",

    "legalnotice.h8": "Droit applicable et juridiction",
    "legalnotice.t8.a":
        "Les présentes mentions légales sont régies par le droit suisse. En cas de litige, les tribunaux du canton du Vaud seront compétents.",

    "legalnotice.h9": "Langue",
    "legalnotice.t9.a":
        "Le Site est disponible en français et anglais. En cas de divergence ou d'ambiguïté entre les différentes versions linguistiques des présentes mentions légales ou de tout autre contenu du Site, la version française prévaut et fait foi.",

    "legalnotice.lastUpdated": "Date de dernière mise à jour: 28/09/2024",
};

export default frMessages;
