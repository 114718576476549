import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// Main component for the Translation page
function Translation() {
    // Array of FAQ items with questions and answers
    const faqItems = [
        {
            question: <FormattedMessage id="translation.faq.question1" />,
            answer: <FormattedMessage id="translation.faq.answer1" />,
        },
        {
            question: <FormattedMessage id="translation.faq.question2" />,
            answer: <FormattedMessage id="translation.faq.answer2" />,
        },
        {
            question: <FormattedMessage id="translation.faq.question3" />,
            answer: <FormattedMessage id="translation.faq.answer3" />,
        },
        {
            question: <FormattedMessage id="translation.faq.question4" />,
            answer: <FormattedMessage id="translation.faq.answer4" />,
        },

        // Add more FAQ items as needed
    ];

    // State to manage which dropdown is currently open
    const [openDropdown, setOpenDropdown] = useState(null);

    // Function to toggle the dropdown state
    const toggleDropdown = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    return (
        <div className="flex-grow">
            {/* Hero section */}
            <div className="bg-beige py-12 sm:py-16 md:py-20 lg:py-24 px-4 sm:px-6 lg:px-8">
                <div className="container mx-auto px-4 max-w-6xl">
                    <div className="flex flex-col md:flex-row md:space-x-6 lg:space-x-8">
                        {/* Left side - Title, description, and CTA */}
                        <div className="md:w-1/2 mb-8 md:mb-0 px-4">
                            <h1 className="text-black mb-6">
                                <FormattedMessage id="translation.title" />
                            </h1>
                            <p className="text-black mb-8">
                                <FormattedMessage id="translation.description" />
                            </p>
                            <Link to="/contactme" className="btn-primary">
                                <FormattedMessage id="translation.contactButton" />
                            </Link>
                        </div>

                        {/* Right side - Dropdown blocks */}
                        <div className="md:w-1/2">
                            <div className="space-y-0">
                                {/* Mapping through blocks to create dropdowns */}
                                {["block1", "block2", "block3", "block4"].map(
                                    (block, index) => (
                                        <Dropdown
                                            key={index}
                                            title={
                                                <FormattedMessage
                                                    id={`translation.${block}.title`}
                                                />
                                            }
                                            description={
                                                <FormattedMessage
                                                    id={`translation.${block}.description`}
                                                />
                                            }
                                            isOpen={openDropdown === index}
                                            toggleDropdown={() =>
                                                toggleDropdown(index)
                                            }
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Commented out packages section */}
            {/* <div className="bg-[#ede6d6] py-20">
                <div className="container mx-auto px-4">
                    <h2 className="font-bold text-center">
                        <FormattedMessage id="translation.packages" />
                    </h2>
                    <h3 className="text-center font-semibold mt-10 md:mt-5">
                        <FormattedMessage id="translation.packagesText" />
                    </h3>
                    <p className="text-center mt-10 md:mt-5">
                        <FormattedMessage id="translation.packagesText2" />
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
                        {packages.map((pkg) => (
                            <div
                                className=" overflow-hidden shadow-lg p-4"
                                key={pkg.id}
                            >
                                <PackageCard pkg={pkg} />
                            </div>
                        ))}
                    </div>
                </div>
            </div> */}

            {/* FAQ section */}
            <div className="bg-white py-16">
                <div className="container mx-auto px-4">
                    <h2 className=" font-bold pb-10 text-center">
                        <FormattedMessage id="translation.faq" />
                    </h2>
                    <FAQ items={faqItems} />
                </div>
            </div>

            {/* Contact CTA section */}
            <div className="bg-beige py-20">
                <div className="container mx-auto px-4 max-w-6xl">
                    <div className="flex flex-col items-center text-center px-4">
                        <h3 className="mb-8">
                            <FormattedMessage id="translation.contact" />
                        </h3>
                        <Link to="/contactme" className="btn-primary">
                            <FormattedMessage id="translation.contactButton" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Dropdown component for displaying collapsible content
function Dropdown({ title, description, isOpen, toggleDropdown }) {
    return (
        <div className="border-blast:border-b-0">
            {/* Dropdown button */}
            <button
                onClick={toggleDropdown}
                className="flex justify-between items-center w-full p-3 sm:p-4 text-left text-black"
            >
                <span className="text-base md:text-lg lg:text-lg font-merriweather ml-2 sm:ml-4 md:ml-4 lg:ml-8">
                    {title}
                </span>
                <span className="text-lg md:text-xl lg:text-2xlflex-shrink-0 mr-2 sm:mr-4 md:mr-4 lg:mr-8">
                    {isOpen ? "-" : "+"}
                </span>
            </button>
            {/* Dropdown content */}
            {isOpen && (
                <div className="p-3 sm:p-4 mx-2 sm:mx-4 md:mx-4 lg:mx-8">
                    <p className="text-black">{description}</p>
                </div>
            )}
        </div>
    );
}

// // PackageCard component for displaying package information (currently unused)
// function PackageCard({ pkg }) {
//     const [openDropdown, setOpenDropdown] = useState(null);

//     const toggleDropdown = (index) => {
//         setOpenDropdown(openDropdown === index ? null : index);
//     };

//     return (
//         <div className=" p-4 max-w-xs mx-auto md:max-w-md lg:max-w-lg">
//             {/* Package image */}
//             <img
//                 src={pkg.image}
//                 alt={pkg.title}
//                 className="w-full h-auto mb-2"
//             />

//             {/* Package points as dropdowns */}
//             {pkg.points.map((point, index) => (
//                 <Dropdown
//                     key={index}
//                     title={point.title}
//                     description={point.description}
//                     isOpen={openDropdown === index}
//                     toggleDropdown={() => toggleDropdown(index)}
//                 />
//             ))}
//             {/* Package price */}
//             <p className="text-xl font-bold mt-2 mb-2">{pkg.price}</p>
//         </div>
//     );
// }

// FAQ component for displaying frequently asked questions
function FAQ({ items }) {
    const [openItem, setOpenItem] = useState(null);

    const toggleItem = (index) => {
        setOpenItem(openItem === index ? null : index);
    };

    return (
        <div className="space-y-0 max-w-4xl mx-auto px-8 sm:px-10 md:px-12 lg:px-16">
            {/* Mapping through FAQ items */}
            {items.map((item, index) => (
                <div
                    key={index}
                    className="border-b-2 border-gray-200 last:border-b-0"
                >
                    {/* FAQ question button */}
                    <button
                        className="flex justify-between items-center w-full py-4 text-left text-black"
                        onClick={() => toggleItem(index)}
                    >
                        <span className="text-base md:text-lg lg:text-lg font-merriweather pr-8 flex-grow">
                            {item.question}
                        </span>
                        <span className="text-lg md:text-xl lg:text-2xl flex-shrink-0">
                            {openItem === index ? "-" : "+"}
                        </span>
                    </button>
                    {/* FAQ answer */}
                    {openItem === index && (
                        <div className="pb-4">
                            <p className="text-black">{item.answer}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default Translation;
