import React, { useState, useEffect } from "react";
import { IntlContext, FormattedMessage } from "react-intl";
import { loadStripe } from "@stripe/stripe-js";
import package1Image from "../assets/images/placeholder.png";
import package2Image from "../assets/images/superior.png";
import { API_URL } from "../config";

// Main Shop component for displaying and handling product purchases
function Shop() {
    // State for storing the Stripe Promise and any errors
    const [stripePromise, setStripePromise] = useState(null);
    const [error, setError] = useState(null);

    // Effect to initialize Stripe with the public key
    useEffect(() => {
        const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
        console.log("Using Stripe public key:", stripePublicKey);
        if (stripePublicKey) {
            setStripePromise(loadStripe(stripePublicKey));
        } else {
            setError("Stripe public key is not set..");
        }
    }, []);

    // Array of product objects with details for each product
    const products = [
        {
            id: 1,
            titleKey: "Guide",
            image: package1Image,
            descriptionKey: "shop.description.1",
            price: 25,
            pdfPath: "/downloads/Guide.pdf",
        },
        {
            id: 2,
            titleKey: "shop.title.2",
            image: package2Image,
            descriptionKey: "shop.description.2",
            price: 100,
        },
    ];

    // Function to handle the purchase process
    const handlePurchase = async (product) => {
        if (!stripePromise) {
            setError("Payment system is not ready. Please try again later.");
            return;
        }

        try {
            // Initialize Stripe
            const stripe = await stripePromise;
            if (!stripe) {
                throw new Error("Failed to initialize Stripe");
            }

            console.log("Sending request to create checkout session");
            // Create a checkout session on the server
            const response = await fetch(`${API_URL}/create-checkout-session`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    productId: product.id,
                    productName: product.titleKey,
                    price: product.price,
                    pdfPath: product.pdfPath,
                }),
            });

            // Handle server response errors
            if (!response.ok) {
                const errorData = await response.json();
                console.error("Server response:", errorData);
                throw new Error(
                    `HTTP error! status: ${response.status}, message: ${errorData.error}`
                );
            }

            const session = await response.json();
            console.log("Checkout session created:", session.id);

            // Redirect to Stripe Checkout
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                console.error("Stripe redirectToCheckout error:", result.error);
                throw new Error(result.error.message);
            }
        } catch (error) {
            console.error("Detailed error in handlePurchase:", error);
            setError(
                "An error occurred while processing your purchase. Please try again."
            );
        }
    };

    // Render error message if there's an error
    if (error) {
        return (
            <div className="bg-[#ede6d6] pb-20 px-8">
                <div className="container mx-auto px-4 py-12">
                    <h1 className="pt-10 pb-10 text-black text-center">
                        <FormattedMessage id="shop.title" />
                    </h1>
                    <p className="text-center text-red-500">{error}</p>
                </div>
            </div>
        );
    }

    // Render the main shop component
    return (
        <div className="bg-[#ede6d6] pb-20 px-8">
            <div className="container mx-auto px-4 py-12">
                <h1 className="pt-10 pb-10 text-black text-center">
                    <FormattedMessage id="shop.title" />
                </h1>
                <p className="text-center text-black max-w-2xl mx-auto pb-16">
                    <FormattedMessage id="shop.description" />
                </p>

                {/* Grid layout for product cards */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
                    {products.map((product) => (
                        <div key={product.id} className="flex justify-center">
                            <PackageCard
                                pkg={product}
                                onPurchase={handlePurchase}
                            />
                        </div>
                    ))}
                </div>
                <p className="italic text-center pt-12 sm:pt-16 md:pt-20 text-black">
                    <FormattedMessage id="shop.disclaimer" />
                </p>
            </div>
        </div>
    );
}

// PackageCard component for displaying individual product details
function PackageCard({ pkg, onPurchase }) {
    return (
        <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col h-full w-full max-w-sm">
            <div className="w-full aspect-w-16 aspect-h-9 mb-4 overflow-hidden">
                <img
                    src={pkg.image}
                    alt={<FormattedMessage id={pkg.titleKey} />}
                    className="w-full h-full object-contain rounded"
                />
            </div>
            <h3 className="text-lg mb-2">
                <FormattedMessage id={pkg.titleKey} />
            </h3>
            <p className="text-sm mb-4 flex-grow">
                <FormattedMessage id={pkg.descriptionKey} />
            </p>
            <div className="mt-auto">
                <p className="text-lg font-semibold font-merriweather mb-3 text-black">
                    {pkg.price.toFixed(2)} CHF
                </p>
                <button
                    className="btn-primary py-3 text-lg w-full"
                    onClick={() => onPurchase(pkg)}
                >
                    <FormattedMessage id="shop.purchase.button" />
                </button>
            </div>
        </div>
    );
}

export default Shop;
