import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

// Define content for work sections using FormattedMessage for internationalization
const work = {
    title: <FormattedMessage id="work.title" />,
    section1: <FormattedMessage id="work.section1" />,
    description1: <FormattedMessage id="work.description1" />,
    section2: <FormattedMessage id="work.section2" />,
    description2: <FormattedMessage id="work.description2" />,
    section3: <FormattedMessage id="work.section3" />,
    description3: <FormattedMessage id="work.description3" />,
};

// Dropdown component for expandable sections
function Dropdown({ title, content }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mb-4 w-full">
            <button
                className="flex justify-between items-center w-full p-4 bg-burgundy text-beige text-left font-merriweather text-base sm:text-base md:text-lg"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>{title}</span>
                <ChevronDownIcon
                    className={`w-6 h-6 transition-transform ${
                        isOpen ? "rotate-180" : ""
                    }`}
                />
            </button>
            {isOpen && (
                <div className="p-4 bg-burgundy text-beige">
                    <p className="">{content}</p>
                </div>
            )}
        </div>
    );
}

// Main Services component
export default function Services() {
    return (
        <section className="bg-burgundy text-beige">
            <div className="container mx-auto px-4 py-10 flex flex-col md:flex-row items-center">
                {/* Left column with title */}
                <div className="md:w-1/2 md:mb-0 flex items-center justify-center h-full">
                    <h2 className="font-light mb-8 text-center md:mr-10">
                        {work.title}
                    </h2>
                </div>
                {/* Right column with dropdown sections */}
                <div className="md:w-1/2 flex flex-col items-center w-full">
                    <div className="w-full">
                        <Dropdown
                            title={work.section1}
                            content={work.description1}
                        />
                        <hr className="border-t border-beige opacity-30 my-4" />
                        <Dropdown
                            title={work.section2}
                            content={work.description2}
                        />
                        <hr className="border-t border-beige opacity-30 my-4" />
                        <Dropdown
                            title={work.section3}
                            content={work.description3}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
